import { createAction, props } from '@ngrx/store';
import { User } from '@app/shared/models/user';

export const decodeMessage = createAction(
  '[InviteActions] Decode data user',
  props<{ message: string }>()
);

export const decodeMessageSuccess = createAction(
  '[InviteActions] Decode data success user',
  props<{ decodedMessage: any }>()
);

export const decodeMessageFailure = createAction(
  '[InviteActions] Decode Message failure user',
  props<{ error: any }>()
);

export const acceptInvitation = createAction(
  '[InviteActions] Accept invitation',
  props<{ password: any }>()
);

export const acceptInvitationSuccess = createAction(
  '[InviteActions] Accept invitation success',
  props<{ user: any }>()
);

export const inviteTokenExpired = createAction(
  '[InviteActions] Token expired user',
  props<{ error: any }>()
);

export const acceptInvitationFailure = createAction(
  '[InviteActions] Accept invitation failure',
  props<{ error: any }>()
);




