import { UserBillingActions } from '@app/client/account/admin/billing/actions';
import { SubscriptionActions } from '@app/client/signup/subscription/actions';
import { Identity } from '@app/shared/models/identity';
import { Role } from '@app/shared/models/role';
import { Team } from '@app/shared/models/team';
import { Action, createReducer, on } from '@ngrx/store';
import { Constant } from 'src/constant';
import { AccountActions, PaymentActions } from '../actions';
import { ListSubscriptionLimitsOutput } from '@smash-sdk/billing/ejs/07-2021';
import { SigninActions } from '@app/client/signin/store/actions';
import { GetMfaOutput } from '@smash-sdk/iam';

export const userFeatureKey = 'account';

export interface State {
  activeIdentity: Identity;
  activeIdentityUpdating: boolean;
  activeIdentityUpdated: boolean;
  activeIdentityUpdateError: any;

  username: Identity;
  usernameUpdating: boolean;
  usernameUpdated: boolean;
  usernameUpdateError: any;

  passwordUpdating: boolean;
  passwordUpdated: boolean;
  passwordUpdateError: any;

  isAdministrator: boolean;
  userRoles: Role[];
  userRolesLoading: boolean;
  userRolesLoaded: boolean;
  userTeams: Team[];
  userTeamsLoading: boolean;
  userTeamsLoaded: boolean;
  userRolesAndTeamsLoaded: boolean;

  activeTeam: Team;

  subscription: any;
  subscriptionUpdating: boolean;
  subscriptionLoaded: boolean;

  subscriptionLimits: ListSubscriptionLimitsOutput['limits'];
  subscriptionLimitsLoading: boolean;
  subscriptionLimitsLoaded: boolean;
  subscriptionLimitsError: any;

  plan: any;
  planLoaded: boolean;

  billingInformations: any;
  billingInformationsLoaded: boolean;

  refreshTokenLoading: boolean;
  refreshTokenLoaded: boolean;
  refreshTokenError: any;

  setupSsoSessionProcessing: boolean;
  setupSsoSessionSuccess: boolean;
  setupSsoSessionFailure: boolean;
  setupSsoSessionErrorMessage: any;

  userContacts: string[];

  errorMessage: string;

  mfa: GetMfaOutput['mfa'];
  getMfaLoading: boolean;
  getMfaLoaded: boolean;
  getMfaError: any;

  updateDefaultFactorLoading: boolean;
  updateDefaultFactorLoaded: boolean;
  updateDefaultFactorError: any;

  verifyApplicationFactorLoading: boolean;
  verifyApplicationFactorLoaded: boolean;
  verifyApplicationFactorError: any;

  verifyEmailFactorLoading: boolean;
  verifyEmailFactorLoaded: boolean;
  verifyEmailFactorError: any;

  sendEmailOtpLoading: boolean;
  sendEmailOtpLoaded: boolean;
  sendEmailOtpError: any;

  resetEmailFactorLoading: boolean;
  resetEmailFactorLoaded: boolean;
  resetEmailFactorError: any;

  resetApplicationFactorLoading: boolean;
  resetApplicationFactorLoaded: boolean;
  resetApplicationFactorError: any;

  createMfaApplicationFactorSecretLoading: boolean;
  createMfaApplicationFactorSecretLoaded: boolean;
  createMfaApplicationFactorSecretError: any;
  applicationSecret: string;
}

export const initialState: State = {
  activeIdentity: null,
  activeIdentityUpdating: false,
  activeIdentityUpdated: false,
  activeIdentityUpdateError: null,

  username: null,
  usernameUpdating: false,
  usernameUpdated: false,
  usernameUpdateError: null,

  passwordUpdating: false,
  passwordUpdated: false,
  passwordUpdateError: null,

  userRoles: [],
  userRolesLoading: false,
  userRolesLoaded: false,
  userTeams: [],
  userTeamsLoading: false,
  userTeamsLoaded: false,
  userRolesAndTeamsLoaded: false,
  isAdministrator: false,

  activeTeam: null,

  subscription: null,
  subscriptionUpdating: false,
  subscriptionLoaded: false,

  subscriptionLimits: null,
  subscriptionLimitsLoading: false,
  subscriptionLimitsLoaded: false,
  subscriptionLimitsError: null,

  plan: null,
  planLoaded: false,

  billingInformations: null,
  billingInformationsLoaded: false,

  refreshTokenLoading: false,
  refreshTokenLoaded: false,
  refreshTokenError: null,

  setupSsoSessionProcessing: false,
  setupSsoSessionSuccess: false,
  setupSsoSessionFailure: false,
  setupSsoSessionErrorMessage: null,

  userContacts: [],

  errorMessage: null,

  mfa: null,
  getMfaLoading: false,
  getMfaLoaded: false,
  getMfaError: null,

  updateDefaultFactorLoading: false,
  updateDefaultFactorLoaded: false,
  updateDefaultFactorError: null,

  verifyApplicationFactorLoading: false,
  verifyApplicationFactorLoaded: false,
  verifyApplicationFactorError: null,

  verifyEmailFactorLoading: false,
  verifyEmailFactorLoaded: false,
  verifyEmailFactorError: null,

  sendEmailOtpLoading: false,
  sendEmailOtpLoaded: false,
  sendEmailOtpError: null,

  resetEmailFactorLoading: false,
  resetEmailFactorLoaded: false,
  resetEmailFactorError: null,

  resetApplicationFactorLoading: false,
  resetApplicationFactorLoaded: false,
  resetApplicationFactorError: null,

  createMfaApplicationFactorSecretLoading: false,
  createMfaApplicationFactorSecretLoaded: false,
  createMfaApplicationFactorSecretError: null,
  applicationSecret: null,
};

const userReducer = createReducer(
  initialState,
  // Create anonymous user
  on(AccountActions.createUserAnonymous, (state) => ({
    ...state,
    activeIdentity: null,
    activeIdentityUpdating: true,
  })),
  on(AccountActions.createUserAnonymousSuccess, (state, { identity }) => ({
    ...state,
    activeIdentity: identity,
    activeIdentityUpdating: false,
  })),
  on(AccountActions.createUserAnonymousFailure, (state, { error }) => ({
    ...state,
    activeIdentityUpdating: false,
    error
  })),
  // Select Active Identity
  on(AccountActions.selectActiveIdentity, SubscriptionActions.updateAccountSuccess,
    SigninActions.signinSuccess, (state, { identity }) => ({
      ...state,
      activeIdentity: identity,
    })),
  on(AccountActions.selectActiveTeam, (state, { team }) => ({
    ...state,
    activeTeam: team,
  })),
  on(AccountActions.updateActiveTeam, (state, { team }) => ({
    ...state,
    activeTeam: team,
  })),
  // Profile Informations
  on(AccountActions.getProfileInformationSuccess, (state, { firstName, lastName, authenticationMode }) => ({
    ...state,
    activeIdentity: { ...state.activeIdentity, firstName, lastName, authenticationMode }
  })),
  // Refresh token
  on(AccountActions.refreshToken, (state) => ({
    ...state,
    refreshTokenLoading: true,
    refreshTokenLoaded: initialState.refreshTokenLoaded,
    refreshTokenError: initialState.refreshTokenError,
  })),
  on(AccountActions.refreshTokenSuccess, (state, { identity }) => ({
    ...state,
    activeIdentity: { ...state.activeIdentity, ...identity },
    refreshTokenLoading: false,
    refreshTokenLoaded: true,
  })),
  on(AccountActions.refreshTokenFailure, (state, { error }) => ({
    ...state,
    refreshTokenLoading: false,
    refreshTokenError: error,
    error,
  })),
  // Load Billing Informations
  on(AccountActions.loadBillingInformations, (state) => ({
    ...state,
    billingInformations: initialState.billingInformations,
    billingInformationsLoaded: false,
  })),
  on(AccountActions.loadBillingInformationsSuccess, (state, { billingInformations }) => {
    return {
      ...state,
      billingInformations,
      billingInformationsLoaded: true,
    };
  }),
  on(AccountActions.loadBillingInformationsFailure, (state, { error }) => ({
    ...state,
    error
  })),
  // Update Billing Informations
  on(SubscriptionActions.updateBillingInformationsSuccess, (state, { billingInformations }) => ({
    ...state,
    billingInformations
  })),
  // Load Subscription
  on(AccountActions.loadSubscription, (state) => ({
    ...state,
    subscription: initialState.subscription,
    subscriptionUpdating: true,
    subscriptionLoaded: initialState.subscriptionLoaded,
  })),
  on(AccountActions.loadSubscriptionWithPlan, (state) => ({
    ...state,
    subscription: initialState.subscription,
    subscriptionUpdating: true,
    subscriptionLoaded: initialState.subscriptionLoaded,
    subscriptionLimits: initialState.subscriptionLimits,
    subscriptionLimitsLoading: true,
    subscriptionLimitsLoaded: initialState.subscriptionLimitsLoaded,
  })),
  // Load Subscription Success
  on(AccountActions.loadSubscriptionSuccess, AccountActions.loadSubscriptionWithPlanSuccess, (state, { subscription, limits }) => ({
    ...state,
    subscription: { ...state.subscription, ...subscription },
    subscriptionUpdating: false,
    subscriptionLoaded: true,
    subscriptionLimits: limits,
    subscriptionLimitsLoading: false,
    subscriptionLimitsLoaded: true,
  })),
  on(UserBillingActions.loadSubscriptionAndPlanSuccess, (state, { subscription }) => {
    return {
      ...state,
      subscription: { ...state.subscription, ...subscription },
      subscriptionUpdating: false,
      subscriptionLoaded: true,
    };
  }),
  on(AccountActions.loadSubscriptionFailure, AccountActions.loadSubscriptionWithPlanFailure, (state, { error }) => ({
    ...state,
    subscriptionUpdating: false,
    subscriptionLimitsError: error,
    error
  })),
  // Load plan
  on(AccountActions.loadPlanSuccess, (state, { plan }) => ({
    ...state,
    plan: { ...state.plan, ...plan },
    planLoaded: true,
  })),
  on(AccountActions.loadSubscriptionWithPlanSuccess, (state, { subscription }) => ({
    ...state,
    plan: { ...state.plan, ...subscription?.plan },
    subscriptionUpdating: false,
    planLoaded: true,
  })),
  on(SubscriptionActions.loadPlanSuccess, (state, { plan }) => ({
    ...state,
    plan: { ...state.plan, ...plan },
    planLoaded: true,
  })),
  on(AccountActions.loadPlanFailure, (state, { error }) => ({
    ...state,
    error
  })),
  // Update Subscription
  on(SubscriptionActions.updateSubscriptionSuccess, (state, { subscription }) => ({
    ...state,
    subscription,
    subscriptionUpdating: false,
  })),
  on(AccountActions.signOutSuccess, (state) => ({
    ...state,
    activeTeam: initialState.activeTeam,
    activeIdentity: initialState.activeIdentity,
    subscription: initialState.subscription,
    subscriptionLoaded: initialState.subscriptionLoaded,
    plan: initialState.plan
  })),
  on(AccountActions.signInAgain, (state) => ({
    ...state,
    activeIdentity: initialState.activeIdentity
  })),
  on(SubscriptionActions.signupSuccess, (state) => ({
    ...state,
    subscription: { ...state.subscription, status: Constant.subscriptionStatus.Pending },
  })),
  on(PaymentActions.processStripePaymentSuccess, (state, { response }) => ({
    ...state,
    subscription: { ...state.subscription, status: Constant.subscriptionStatus.Pending },
    plan: response.plan,
    billingInformations: response.informations,
  })),
  on(PaymentActions.processPaypalPaymentSuccess, (state, { response }) => ({
    ...state,
    subscription: response.subscription,
    plan: response.plan,
    billingInformations: response.informations,
  })),
  // Setup Sso Session
  on(AccountActions.SetupSsoSession, (state) => ({
    ...state,
    setupSsoSessionProcessing: true,
    setupSsoSessionSuccess: initialState.setupSsoSessionSuccess,
    setupSsoSessionFailure: initialState.setupSsoSessionFailure,
    setupSsoSessionErrorMessage: initialState.setupSsoSessionErrorMessage,
  })),
  on(AccountActions.SetupSsoSessionSuccess, (state) => ({
    ...state,
    setupSsoSessionProcessing: initialState.setupSsoSessionProcessing,
    setupSsoSessionSuccess: true,
  })),
  on(AccountActions.SetupSsoSessionFailure, (state, { error }) => ({
    ...state,
    setupSsoSessionProcessing: initialState.setupSsoSessionProcessing,
    setupSsoSessionFailure: true,
    setupSsoSessionErrorMessage: error,
  })),
  // Load User Roles
  on(AccountActions.LoadUserRoles, (state) => ({
    ...state,
    userRolesLoading: true,
    userRolesLoaded: initialState.userRolesLoaded,
    userRoles: initialState.userRoles,
    isAdministrator: initialState.isAdministrator,
  })),
  on(AccountActions.LoadUserRolesSuccess, (state, { roles, isAdministrator }) => ({
    ...state,
    userRolesLoaded: true,
    userRolesLoading: false,
    userRoles: roles,
    isAdministrator,
  })),
  on(AccountActions.LoadUserRolesFailure, (state, { error }) => ({
    ...state,
    error,
    userRolesLoading: initialState.userRolesLoading,
    userRolesLoaded: initialState.userRolesLoaded,
    userRoles: initialState.userRoles,
    isAdministrator: initialState.isAdministrator,
  })),
  // Load User Roles And Teams
  on(AccountActions.LoadUserRolesAndTeams, (state) => ({
    ...state,
    userRolesAndTeamsLoaded: initialState.userRolesAndTeamsLoaded,
    userRoles: initialState.userRoles,
    userTeams: initialState.userTeams,
    isAdministrator: initialState.isAdministrator,
  })),
  on(AccountActions.LoadUserRolesAndTeamsSuccess, (state, { roles, teams, isAdministrator }) => ({
    ...state,
    userRolesAndTeamsLoaded: true,
    userRoles: roles,
    userTeams: teams,
    isAdministrator,
  })),
  on(AccountActions.LoadUserTeams, (state) => ({
    ...state,
    userTeamsLoading: true,
    userTeamsLoaded: initialState.userTeamsLoaded,
    userTeams: initialState.userTeams,
  })),
  on(AccountActions.LoadUserTeamsSuccess, (state, { teams }) => ({
    ...state,
    userTeamsLoaded: true,
    userTeamsLoading: false,
    userTeams: teams,
  })),
  on(AccountActions.LoadUserTeamsFailure, (state, { error }) => ({
    ...state,
    error,
    userTeamsLoading: false,
    userTeamsLoaded: initialState.userTeamsLoaded,
    userTeams: initialState.userTeams,
  })),

  // Update Identity
  on(AccountActions.UpdateActiveIdentity, (state) => ({
    ...state,
    activeIdentityUpdating: true,
    activeIdentityUpdated: initialState.activeIdentityUpdated,
    activeIdentityUpdateError: initialState.activeIdentityUpdateError,
  })),
  on(AccountActions.UpdateActiveIdentitySuccess, (state, { identity }) => ({
    ...state,
    activeIdentityUpdated: true,
    activeIdentityUpdating: initialState.activeIdentityUpdating,
    activeIdentity: { ...state.activeIdentity, ...identity },
    activeIdentityUpdateError: initialState.activeIdentityUpdateError,
  })),
  on(AccountActions.UpdateActiveIdentityFailure, (state, { error }) => ({
    ...state,
    activeIdentityUpdating: initialState.activeIdentityUpdating,
    activeIdentityUpdateError: error,
  })),
  // Update Username
  on(AccountActions.UpdateUsername, (state) => ({
    ...state,
    activeIdentityUpdating: true,
    activeIdentityUpdated: initialState.activeIdentityUpdated,
    activeIdentityUpdateError: initialState.activeIdentityUpdateError,
    usernameUpdating: true,
    usernameUpdated: initialState.usernameUpdated,
    usernameUpdateError: initialState.usernameUpdateError,
  })),
  on(AccountActions.UpdateUsernameSuccess, (state, { identity }) => ({
    ...state,
    activeIdentityUpdated: true,
    activeIdentityUpdating: initialState.activeIdentityUpdating,
    activeIdentityUpdateError: initialState.activeIdentityUpdateError,
    usernameUpdated: true,
    usernameUpdating: initialState.usernameUpdating,
    usernameUpdateError: initialState.usernameUpdateError,
    activeIdentity: { ...state.activeIdentity, ...identity },
  })),
  on(AccountActions.UpdateUsernameFailure, (state, { error }) => ({
    ...state,
    activeIdentityUpdating: initialState.activeIdentityUpdating,
    activeIdentityUpdateError: error,
    usernameUpdating: initialState.usernameUpdating,
    usernameUpdateError: error,
  })),
  // Update Password
  on(AccountActions.UpdatePassword, (state) => ({
    ...state,
    passwordUpdating: true,
    passwordUpdated: initialState.passwordUpdated,
    passwordUpdateError: initialState.passwordUpdateError,
  })),
  on(AccountActions.UpdatePasswordSuccess, (state, { identity }) => ({
    ...state,
    activeIdentity: identity,
    passwordUpdated: true,
    passwordUpdating: initialState.passwordUpdating,
    passwordUpdateError: initialState.passwordUpdateError,
  })),
  on(AccountActions.UpdatePasswordFailure, (state, { error }) => ({
    ...state,
    passwordUpdating: initialState.passwordUpdating,
    passwordUpdateError: error,
  })),

  on(AccountActions.SetUserContacts, (state, { userContacts = [] }) => {
    const initUserContacts = state.userContacts || [];
    const filteredcontacts = userContacts.filter(contact => initUserContacts.indexOf(contact) === -1);
    return {
      ...state,
      userContacts: [...initUserContacts, ...filteredcontacts]
    };
  }),
  on(AccountActions.updateUserContacts, (state, { userContacts }) => ({ ...state, userContacts })),
    // GetMfa
    on(AccountActions.GetMfa, (state) => ({
      ...state,
      getMfaLoading: true,
      getMfaLoaded: false,
      getMfaError: null,
    })),
    on(AccountActions.GetMfaSuccess, (state, { mfa }) => ({
      ...state,
      mfa,
      getMfaLoading: false,
      getMfaLoaded: true,
      getMfaError: null
    })),
    on(AccountActions.GetMfaFailure, (state, { error }) => ({
      ...state,
      getMfaLoading: false,
      getMfaLoaded: false,
      getMfaError: error,
    })),
  
    // VerifyApplicationFactor
    on(AccountActions.VerifyApplicationFactor, (state) => ({
      ...state,
      verifyApplicationFactorLoading: true,
      verifyApplicationFactorLoaded: false,
      verifyApplicationFactorError: null,
    })),
    on(AccountActions.VerifyApplicationFactorSuccess, (state) => ({
      ...state,
      verifyApplicationFactorLoading: false,
      verifyApplicationFactorLoaded: true,
      verifyApplicationFactorError: null,
    })),
    on(AccountActions.VerifyApplicationFactorFailure, (state, { error }) => ({
      ...state,
      verifyApplicationFactorLoading: false,
      verifyApplicationFactorLoaded: false,
      verifyApplicationFactorError: error,
    })),
  
    // VerifyEmailFactor
    on(AccountActions.VerifyEmailFactor, (state) => ({
      ...state,
      verifyEmailFactorLoading: true,
      verifyEmailFactorLoaded: false,
      verifyEmailFactorError: null,
    })),
    on(AccountActions.VerifyEmailFactorSuccess, (state) => ({
      ...state,
      verifyEmailFactorLoading: false,
      verifyEmailFactorLoaded: true,
      verifyEmailFactorError: null,
    })),
    on(AccountActions.VerifyEmailFactorFailure, (state, { error }) => ({
      ...state,
      verifyEmailFactorLoading: false,
      verifyEmailFactorLoaded: false,
      verifyEmailFactorError: error,
    })),
  
    // SendEmailOtp
    on(AccountActions.SendEmailOtp, (state) => ({
      ...state,
      sendEmailOtpLoading: true,
      sendEmailOtpLoaded: false,
      sendEmailOtpError: null,
    })),
    on(AccountActions.SendEmailOtpSuccess, (state) => ({
      ...state,
      sendEmailOtpLoading: false,
      sendEmailOtpLoaded: true,
      sendEmailOtpError: null,
    })),
    on(AccountActions.SendEmailOtpFailure, (state, { error }) => ({
      ...state,
      sendEmailOtpLoading: false,
      sendEmailOtpLoaded: false,
      sendEmailOtpError: error,
    })),
  
    // ResetEmailFactor
    on(AccountActions.ResetEmailFactor, (state) => ({
      ...state,
      resetEmailFactorLoading: true,
      resetEmailFactorLoaded: false,
      resetEmailFactorError: null,
    })),
    on(AccountActions.ResetEmailFactorSuccess, (state) => ({
      ...state,
      resetEmailFactorLoading: false,
      resetEmailFactorLoaded: true,
      resetEmailFactorError: null,
    })),
    on(AccountActions.ResetEmailFactorFailure, (state, { error }) => ({
      ...state,
      resetEmailFactorLoading: false,
      resetEmailFactorLoaded: false,
      resetEmailFactorError: error,
    })),
  
    // ResetApplicationFactor
    on(AccountActions.ResetApplicationFactor, (state) => ({
      ...state,
      resetApplicationFactorLoading: true,
      resetApplicationFactorLoaded: false,
      resetApplicationFactorError: null,
    })),
    on(AccountActions.ResetApplicationFactorSuccess, (state) => ({
      ...state,
      resetApplicationFactorLoading: false,
      resetApplicationFactorLoaded: true,
      resetApplicationFactorError: null,

      verifyApplicationFactorLoading: false,
      verifyApplicationFactorLoaded: false,
      verifyApplicationFactorError: null,

      createMfaApplicationFactorSecretError: null,
      createMfaApplicationFactorSecretLoaded: false,
      createMfaApplicationFactorSecretLoading: false,
    })),
    on(AccountActions.ResetApplicationFactorFailure, (state, { error }) => ({
      ...state,
      resetApplicationFactorLoading: false,
      resetApplicationFactorLoaded: false,
      resetApplicationFactorError: error,
    })),
  
    // UpdateDefaultFactor
    on(AccountActions.UpdateDefaultFactor, (state) => ({
      ...state,
      updateDefaultFactorLoading: true,
      updateDefaultFactorLoaded: false,
      updateDefaultFactorError: null,
    })),
    on(AccountActions.UpdateDefaultFactorSuccess, (state) => ({
      ...state,
      updateDefaultFactorLoading: false,
      updateDefaultFactorLoaded: true,
      updateDefaultFactorError: null,
    })),
    on(AccountActions.UpdateDefaultFactorFailure, (state, { error }) => ({
      ...state,
      updateDefaultFactorLoading: false,
      updateDefaultFactorLoaded: false,
      updateDefaultFactorError: error,
    })),
  
    // Create Mfa Factor Secret
    on(AccountActions.CreateMfaFactorSecret, (state) => ({
      ...state,
      createMfaApplicationFactorSecretLoading: true,
      createMfaApplicationFactorSecretLoaded: false,
      createMfaApplicationFactorSecretError: null,
      secret: null,
    })),
    on(AccountActions.CreateMfaFactorSecretSuccess, (state, { secret }) => ({
      ...state,
      createMfaApplicationFactorSecretLoading: false,
      createMfaApplicationFactorSecretLoaded: true,
      applicationSecret: secret,
    })),
    on(AccountActions.CreateMfaFactorSecretFailure, (state, { error }) => ({
      ...state,
      createMfaApplicationFactorSecretLoading: false,
      createMfaApplicationFactorSecretError: error,
    })),
);

export function reducer(state: State | undefined, action: Action) {
  return userReducer(state, action);
}
