export const environment = {
  clientName: 'LambdastaticSmashApp',
  production: true,
  local: false,
  prefixUrl: '',
  version: require('../../package.json').version,
  mock: false,
  stripeKey: 'pk_live_2RheUfmenIOBfEcCaMlaqnTs',
  cgu: {
    version: require('../../package.json').cgu.version,
    updated: require('../../package.json').cgu.updated,
  },
  googleTagManagerId: 'GTM-TN9DT8V',
  googleTagManagerPreview: 'env-1',
  googleTagManagerAuth: '_19GDOjeSMnbWIyv2zGkvQ',
  LambdastaticSmashAppUserAccessKey: 'AKIAYPP7RU2RCUMUXEMV',
  LambdastaticSmashAppUserSecretKey: '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',
  additionalModules: [],
  captchaKey: '6LcbojImAAAAAALTFPcakjz_gtu5SWl2OoBB6Ow8',
  appDomainWithProtocol: 'https://fromsmash.com',
  domainSdk: 'https://domain.fromsmash.co',
  appDomain: 'fromsmash.com',
  discovery: 'https://discovery.fromsmash.co',
  services: [
    {
      service: "SmashBilling:prod:ca-central-1",
      region: "ca-central-1",
      url: "https://billing.ca-central-1.fromsmash.co",
      name: "billing"
    },
    {
      service: "SmashBilling:prod:eu-central-1",
      region: "eu-central-1",
      url: "https://billing.eu-central-1.fromsmash.co",
      name: "billing"
    },
    {
      service: "SmashBilling:prod:eu-west-1",
      region: "eu-west-1",
      url: "https://billing.eu-west-1.fromsmash.co",
      name: "billing"
    },
    {
      service: "SmashBilling:prod:eu-west-2",
      region: "eu-west-2",
      url: "https://billing.eu-west-2.fromsmash.co",
      name: "billing"
    },
    {
      service: "SmashBilling:prod:eu-west-3",
      region: "eu-west-3",
      url: "https://billing.eu-west-3.fromsmash.co",
      name: "billing"
    },
    {
      service: "SmashBilling:prod:us-east-1",
      region: "us-east-1",
      url: "https://billing.us-east-1.fromsmash.co",
      name: "billing"
    },
    {
      service: "SmashBilling:prod:us-east-2",
      region: "us-east-2",
      url: "https://billing.us-east-2.fromsmash.co",
      name: "billing"
    },
    {
      service: "SmashBilling:prod:us-west-1",
      region: "us-west-1",
      url: "https://billing.us-west-1.fromsmash.co",
      name: "billing"
    },
    {
      service: "SmashBilling:prod:us-west-2",
      region: "us-west-2",
      url: "https://billing.us-west-2.fromsmash.co",
      name: "billing"
    },
    {
      service: "SmashCaptcha:prod:eu-west-3",
      region: "eu-west-3",
      url: "https://captcha.eu-west-3.fromsmash.co",
      name: "captcha"
    },
    {
      service: "SmashCustomization:prod:ca-central-1",
      region: "ca-central-1",
      url: "https://customization.ca-central-1.fromsmash.co",
      name: "customization"
    },
    {
      service: "SmashCustomization:prod:eu-central-1",
      region: "eu-central-1",
      url: "https://customization.eu-central-1.fromsmash.co",
      name: "customization"
    },
    {
      service: "SmashCustomization:prod:eu-west-1",
      region: "eu-west-1",
      url: "https://customization.eu-west-1.fromsmash.co",
      name: "customization"
    },
    {
      service: "SmashCustomization:prod:eu-west-2",
      region: "eu-west-2",
      url: "https://customization.eu-west-2.fromsmash.co",
      name: "customization"
    },
    {
      service: "SmashCustomization:prod:eu-west-3",
      region: "eu-west-3",
      url: "https://customization.eu-west-3.fromsmash.co",
      name: "customization"
    },
    {
      service: "SmashCustomization:prod:us-east-1",
      region: "us-east-1",
      url: "https://customization.us-east-1.fromsmash.co",
      name: "customization"
    },
    {
      service: "SmashCustomization:prod:us-east-2",
      region: "us-east-2",
      url: "https://customization.us-east-2.fromsmash.co",
      name: "customization"
    },
    {
      service: "SmashCustomization:prod:us-west-1",
      region: "us-west-1",
      url: "https://customization.us-west-1.fromsmash.co",
      name: "customization"
    },
    {
      service: "SmashCustomization:prod:us-west-2",
      region: "us-west-2",
      url: "https://customization.us-west-2.fromsmash.co",
      name: "customization"
    },
    {
      service: "SmashDirectory:prod",
      region: "global",
      url: "https://directory.fromsmash.co",
      name: "directory"
    },
    {
      service: "SmashDiscovery:prod:ca-central-1",
      region: "ca-central-1",
      url: "https://discovery.ca-central-1.fromsmash.co",
      name: "discovery"
    },
    {
      service: "SmashDiscovery:prod:eu-central-1",
      region: "eu-central-1",
      url: "https://discovery.eu-central-1.fromsmash.co",
      name: "discovery"
    },
    {
      service: "SmashDiscovery:prod:eu-west-1",
      region: "eu-west-1",
      url: "https://discovery.eu-west-1.fromsmash.co",
      name: "discovery"
    },
    {
      service: "SmashDiscovery:prod:eu-west-2",
      region: "eu-west-2",
      url: "https://discovery.eu-west-2.fromsmash.co",
      name: "discovery"
    },
    {
      service: "SmashDiscovery:prod:eu-west-3",
      region: "eu-west-3",
      url: "https://discovery.eu-west-3.fromsmash.co",
      name: "discovery"
    },
    {
      service: "SmashDiscovery:prod:us-east-1",
      region: "us-east-1",
      url: "https://discovery.us-east-1.fromsmash.co",
      name: "discovery"
    },
    {
      service: "SmashDiscovery:prod:us-east-2",
      region: "us-east-2",
      url: "https://discovery.us-east-2.fromsmash.co",
      name: "discovery"
    },
    {
      service: "SmashDiscovery:prod:us-west-1",
      region: "us-west-1",
      url: "https://discovery.us-west-1.fromsmash.co",
      name: "discovery"
    },
    {
      service: "SmashDiscovery:prod:us-west-2",
      region: "us-west-2",
      url: "https://discovery.us-west-2.fromsmash.co",
      name: "discovery"
    },
    {
      service: "SmashDomain:prod",
      region: "global",
      url: "https://domain.fromsmash.co",
      name: "domain"
    },
    {
      service: "SmashGnitekram:prod:ca-central-1",
      region: "global",
      url: "https://gnitekram.fromsmash.co",
      name: "gnitekram"
    },
    {
      service: "SmashScitylana:prod:ca-central-1",
      region: "ca-central-1",
      url: "https://scitylana.ca-central-1.fromsmash.co",
      name: "scitylana"
    },
    {
      service: "SmashScitylana:prod:eu-central-1",
      region: "eu-central-1",
      url: "https://scitylana.eu-central-1.fromsmash.co",
      name: "scitylana"
    },
    {
      service: "SmashScitylana:prod:eu-west-1",
      region: "eu-west-1",
      url: "https://scitylana.eu-west-1.fromsmash.co",
      name: "scitylana"
    },
    {
      service: "SmashScitylana:prod:eu-west-2",
      region: "eu-west-2",
      url: "https://scitylana.eu-west-2.fromsmash.co",
      name: "scitylana"
    },
    {
      service: "SmashScitylana:prod:eu-west-3",
      region: "eu-west-3",
      url: "https://scitylana.eu-west-3.fromsmash.co",
      name: "scitylana"
    },
    {
      service: "SmashScitylana:prod:us-east-1",
      region: "us-east-1",
      url: "https://scitylana.us-east-1.fromsmash.co",
      name: "scitylana"
    },
    {
      service: "SmashScitylana:prod:us-east-2",
      region: "us-east-2",
      url: "https://scitylana.us-east-2.fromsmash.co",
      name: "scitylana"
    },
    {
      service: "SmashScitylana:prod:us-west-1",
      region: "us-west-1",
      url: "https://scitylana.us-west-1.fromsmash.co",
      name: "scitylana"
    },
    {
      service: "SmashScitylana:prod:us-west-2",
      region: "us-west-2",
      url: "https://scitylana.us-west-2.fromsmash.co",
      name: "scitylana"
    },
    {
      service: "SmashIam:prod:ca-central-1",
      region: "ca-central-1",
      url: "https://iam.ca-central-1.fromsmash.co",
      name: "iam"
    },
    {
      service: "SmashIam:prod:eu-central-1",
      region: "eu-central-1",
      url: "https://iam.eu-central-1.fromsmash.co",
      name: "iam"
    },
    {
      service: "SmashIam:prod:eu-west-1",
      region: "eu-west-1",
      url: "https://iam.eu-west-1.fromsmash.co",
      name: "iam"
    },
    {
      service: "SmashIam:prod:eu-west-2",
      region: "eu-west-2",
      url: "https://iam.eu-west-2.fromsmash.co",
      name: "iam"
    },
    {
      service: "SmashIam:prod:eu-west-3",
      region: "eu-west-3",
      url: "https://iam.eu-west-3.fromsmash.co",
      name: "iam"
    },
    {
      service: "SmashIam:prod:us-east-1",
      region: "us-east-1",
      url: "https://iam.us-east-1.fromsmash.co",
      name: "iam"
    },
    {
      service: "SmashIam:prod:us-east-2",
      region: "us-east-2",
      url: "https://iam.us-east-2.fromsmash.co",
      name: "iam"
    },
    {
      service: "SmashIam:prod:us-west-1",
      region: "us-west-1",
      url: "https://iam.us-west-1.fromsmash.co",
      name: "iam"
    },
    {
      service: "SmashIam:prod:us-west-2",
      region: "us-west-2",
      url: "https://iam.us-west-2.fromsmash.co",
      name: "iam"
    },
    {
      service: "SmashImage:prod:ca-central-1",
      region: "ca-central-1",
      url: "https://image.ca-central-1.fromsmash.co",
      name: "image"
    },
    {
      service: "SmashImage:prod:eu-central-1",
      region: "eu-central-1",
      url: "https://image.eu-central-1.fromsmash.co",
      name: "image"
    },
    {
      service: "SmashImage:prod:eu-west-1",
      region: "eu-west-1",
      url: "https://image.eu-west-1.fromsmash.co",
      name: "image"
    },
    {
      service: "SmashImage:prod:eu-west-2",
      region: "eu-west-2",
      url: "https://image.eu-west-2.fromsmash.co",
      name: "image"
    },
    {
      service: "SmashImage:prod:eu-west-3",
      region: "eu-west-3",
      url: "https://image.eu-west-3.fromsmash.co",
      name: "image"
    },
    {
      service: "SmashImage:prod:us-east-1",
      region: "us-east-1",
      url: "https://image.us-east-1.fromsmash.co",
      name: "image"
    },
    {
      service: "SmashImage:prod:us-east-2",
      region: "us-east-2",
      url: "https://image.us-east-2.fromsmash.co",
      name: "image"
    },
    {
      service: "SmashImage:prod:us-west-1",
      region: "us-west-1",
      url: "https://image.us-west-1.fromsmash.co",
      name: "image"
    },
    {
      service: "SmashImage:prod:us-west-2",
      region: "us-west-2",
      url: "https://image.us-west-2.fromsmash.co",
      name: "image"
    },
    {
      service: "SmashKyc:prod:ca-central-1",
      region: "ca-central-1",
      url: "https://kyc.ca-central-1.fromsmash.co",
      name: "kyc"
    },
    {
      service: "SmashKyc:prod:eu-central-1",
      region: "eu-central-1",
      url: "https://kyc.eu-central-1.fromsmash.co",
      name: "kyc"
    },
    {
      service: "SmashKyc:prod:eu-west-1",
      region: "eu-west-1",
      url: "https://kyc.eu-west-1.fromsmash.co",
      name: "kyc"
    },
    {
      service: "SmashKyc:prod:eu-west-2",
      region: "eu-west-2",
      url: "https://kyc.eu-west-2.fromsmash.co",
      name: "kyc"
    },
    {
      service: "SmashKyc:prod:eu-west-3",
      region: "eu-west-3",
      url: "https://kyc.eu-west-3.fromsmash.co",
      name: "kyc"
    },
    {
      service: "SmashKyc:prod:us-east-1",
      region: "us-east-1",
      url: "https://kyc.us-east-1.fromsmash.co",
      name: "kyc"
    },
    {
      service: "SmashKyc:prod:us-east-2",
      region: "us-east-2",
      url: "https://kyc.us-east-2.fromsmash.co",
      name: "kyc"
    },
    {
      service: "SmashKyc:prod:us-west-1",
      region: "us-west-1",
      url: "https://kyc.us-west-1.fromsmash.co",
      name: "kyc"
    },
    {
      service: "SmashKyc:prod:us-west-2",
      region: "us-west-2",
      url: "https://kyc.us-west-2.fromsmash.co",
      name: "kyc"
    },
    {
      service: "SmashLink:prod",
      region: "global",
      url: "https://link.fromsmash.co",
      name: "link"
    },
    {
      service: "SmashPlugin:prod:ca-central-1",
      region: "ca-central-1",
      url: "https://plugin.ca-central-1.fromsmash.co",
      name: "plugin"
    },
    {
      service: "SmashPlugin:prod:eu-central-1",
      region: "eu-central-1",
      url: "https://plugin.eu-central-1.fromsmash.co",
      name: "plugin"
    },
    {
      service: "SmashPlugin:prod:eu-west-1",
      region: "eu-west-1",
      url: "https://plugin.eu-west-1.fromsmash.co",
      name: "plugin"
    },
    {
      service: "SmashPlugin:prod:eu-west-2",
      region: "eu-west-2",
      url: "https://plugin.eu-west-2.fromsmash.co",
      name: "plugin"
    },
    {
      service: "SmashPlugin:prod:eu-west-3",
      region: "eu-west-3",
      url: "https://plugin.eu-west-3.fromsmash.co",
      name: "plugin"
    },
    {
      service: "SmashPlugin:prod:us-east-1",
      region: "us-east-1",
      url: "https://plugin.us-east-1.fromsmash.co",
      name: "plugin"
    },
    {
      service: "SmashPlugin:prod:us-east-2",
      region: "us-east-2",
      url: "https://plugin.us-east-2.fromsmash.co",
      name: "plugin"
    },
    {
      service: "SmashPlugin:prod:us-west-1",
      region: "us-west-1",
      url: "https://plugin.us-west-1.fromsmash.co",
      name: "plugin"
    },
    {
      service: "SmashPlugin:prod:us-west-2",
      region: "us-west-2",
      url: "https://plugin.us-west-2.fromsmash.co",
      name: "plugin"
    },
    {
      service: "SmashPromotion:prod:ca-central-1",
      region: "ca-central-1",
      url: "https://promotion.ca-central-1.fromsmash.co",
      name: "promotion"
    },
    {
      service: "SmashPromotion:prod:eu-central-1",
      region: "eu-central-1",
      url: "https://promotion.eu-central-1.fromsmash.co",
      name: "promotion"
    },
    {
      service: "SmashPromotion:prod:eu-west-1",
      region: "eu-west-1",
      url: "https://promotion.eu-west-1.fromsmash.co",
      name: "promotion"
    },
    {
      service: "SmashPromotion:prod:eu-west-2",
      region: "eu-west-2",
      url: "https://promotion.eu-west-2.fromsmash.co",
      name: "promotion"
    },
    {
      service: "SmashPromotion:prod:eu-west-3",
      region: "eu-west-3",
      url: "https://promotion.eu-west-3.fromsmash.co",
      name: "promotion"
    },
    {
      service: "SmashPromotion:prod:us-east-1",
      region: "us-east-1",
      url: "https://promotion.us-east-1.fromsmash.co",
      name: "promotion"
    },
    {
      service: "SmashPromotion:prod:us-east-2",
      region: "us-east-2",
      url: "https://promotion.us-east-2.fromsmash.co",
      name: "promotion"
    },
    {
      service: "SmashPromotion:prod:us-west-1",
      region: "us-west-1",
      url: "https://promotion.us-west-1.fromsmash.co",
      name: "promotion"
    },
    {
      service: "SmashPromotion:prod:us-west-2",
      region: "us-west-2",
      url: "https://promotion.us-west-2.fromsmash.co",
      name: "promotion"
    },
    {
      service: "SmashTheme:prod",
      region: "global",
      url: "https://theme.fromsmash.co",
      name: "theme"
    },
    {
      service: "SmashSquarespace:prod",
      region: "global",
      url: "https://squarespace.fromsmash.co",
      name: "squarespace"
    },
    {
      service: "SmashTransfer:prod:ca-central-1",
      region: "ca-central-1",
      url: "https://transfer.ca-central-1.fromsmash.co",
      name: "transfer"
    },
    {
      service: "SmashTransfer:prod:eu-central-1",
      region: "eu-central-1",
      url: "https://transfer.eu-central-1.fromsmash.co",
      name: "transfer"
    },
    {
      service: "SmashTransfer:prod:eu-west-1",
      region: "eu-west-1",
      url: "https://transfer.eu-west-1.fromsmash.co",
      name: "transfer"
    },
    {
      service: "SmashTransfer:prod:eu-west-2",
      region: "eu-west-2",
      url: "https://transfer.eu-west-2.fromsmash.co",
      name: "transfer"
    },
    {
      service: "SmashTransfer:prod:eu-west-3",
      region: "eu-west-3",
      url: "https://transfer.eu-west-3.fromsmash.co",
      name: "transfer"
    },
    {
      service: "SmashTransfer:prod:us-east-1",
      region: "us-east-1",
      url: "https://transfer.us-east-1.fromsmash.co",
      name: "transfer"
    },
    {
      service: "SmashTransfer:prod:us-east-2",
      region: "us-east-2",
      url: "https://transfer.us-east-2.fromsmash.co",
      name: "transfer"
    },
    {
      service: "SmashTransfer:prod:us-west-1",
      region: "us-west-1",
      url: "https://transfer.us-west-1.fromsmash.co",
      name: "transfer"
    },
    {
      service: "SmashTransfer:prod:us-west-2",
      region: "us-west-2",
      url: "https://transfer.us-west-2.fromsmash.co",
      name: "transfer"
    },
    {
      service: "SmashVat:prod:ca-central-1",
      region: "ca-central-1",
      url: "https://vat.ca-central-1.fromsmash.co",
      name: "vat"
    },
    {
      service: "SmashVat:prod:eu-central-1",
      region: "eu-central-1",
      url: "https://vat.eu-central-1.fromsmash.co",
      name: "vat"
    },
    {
      service: "SmashVat:prod:eu-west-1",
      region: "eu-west-1",
      url: "https://vat.eu-west-1.fromsmash.co",
      name: "vat"
    },
    {
      service: "SmashVat:prod:eu-west-2",
      region: "eu-west-2",
      url: "https://vat.eu-west-2.fromsmash.co",
      name: "vat"
    },
    {
      service: "SmashVat:prod:eu-west-3",
      region: "eu-west-3",
      url: "https://vat.eu-west-3.fromsmash.co",
      name: "vat"
    },
    {
      service: "SmashVat:prod:us-east-1",
      region: "us-east-1",
      url: "https://vat.us-east-1.fromsmash.co",
      name: "vat"
    },
    {
      service: "SmashVat:prod:us-east-2",
      region: "us-east-2",
      url: "https://vat.us-east-2.fromsmash.co",
      name: "vat"
    },
    {
      service: "SmashVat:prod:us-west-1",
      region: "us-west-1",
      url: "https://vat.us-west-1.fromsmash.co",
      name: "vat"
    },
    {
      service: "SmashVat:prod:us-west-2",
      region: "us-west-2",
      url: "https://vat.us-west-2.fromsmash.co",
      name: "vat"
    }
  ],
  cloudwatchRegion: 'eu-west-1',
  cloudwatchLogGroupName: 'LambdastaticSmashApp-log-group-prod',
};
