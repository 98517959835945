import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { buildAuthenticatorUrl } from '@app/shared/helpers/mfa';
import { TranslateModule } from '@ngx-translate/core';
import { QRCodeModule } from 'angularx-qrcode';

@Component({
  selector: 'app-smash-mfa-authenticator-setup',
  standalone: true,
  imports: [
    TranslateModule,
    CommonModule,
    QRCodeModule
  ],
  templateUrl: './smash-mfa-authenticator-setup.component.html',
  styleUrl: './smash-mfa-authenticator-setup.component.scss'
})
export class SmashMfaAuthenticatorSetupComponent {
  @Input() secret: string;
  @Input() username: string;

  applicationSecretKeyDisplayed = false;
  authenticatorUrl: string;

  constructor() { }

  ngOnInit() {
    this.buildAuthenticatorUrl();
  }

  buildAuthenticatorUrl() {
    this.authenticatorUrl = buildAuthenticatorUrl({ account: this.username, secret: this.secret });
  }

  toggleSecretKey() {
    this.applicationSecretKeyDisplayed = !this.applicationSecretKeyDisplayed;
  }
}
