import { Plan } from '@app/shared/models/plan';
import { createAction, props } from '@ngrx/store';
import { CreateTokenOutput } from '@smash-sdk/directory/ejs/11-2023/types/CreateToken/CreateToken';
import { GetPlanGroupOutput } from '@smash-sdk/billing/ejs/01-2024/types/GetPlanGroup/GetPlanGroup';
import { ListCountriesOutput } from '@smash-sdk/vat/ejs/10-2019/types/ListCountries/ListCountries';

export const createAccount = createAction(
  '[Signup] Create Account',
);

export const createAccountSuccess = createAction(
  '[Signup] Create Account Success',
  props<{ account: any }>()
);

export const createAccountFailure = createAction(
  '[Signup] Create Account Failure',
  props<{ error: any }>()
);

// Update Data
export const updateFormData = createAction(
  '[Signup] Update Form Data',
  props<{ data: any }>()
);

// Load Plans
export const loadPlans = createAction(
  '[Signup] Load Plans',
  props<{ id: string }>()
);

export const loadPlansSuccess = createAction(
  '[Signup] Load Plans Success',
  props<{ plans: GetPlanGroupOutput['group']['plans'] }>()
);

export const loadPlansFailure = createAction(
  '[Signup] Load Plans Failure',
  props<{ error: any }>()
);

export const computePlan = createAction(
  '[Signup] Compute Plan',
);

export const computePlanSuccess = createAction(
  '[Signup] Compute Plan Success',
  props<{ computedPlan: any }>()
);

export const computePlanFailure = createAction(
  '[Signup] Compute Plan Failure',
  props<{ error: any }>()
);

// Load Countries
export const loadCountries = createAction(
  '[Signup] Load Countries'
);

export const loadCountriesSuccess = createAction(
  '[Signup] Load Countries Success',
  props<{ countries: ListCountriesOutput['countries'] }>()
);

export const loadCountriesFailure = createAction(
  '[Signup] Load Countries Failure',
  props<{ error: any }>()
);

// Load VAT
export const loadVAT = createAction(
  '[Signup] Load VAT'
);

export const loadVATSuccess = createAction(
  '[Signup] Load VAT Success',
  props<{ VATs: any[] }>()
);

export const loadVATFailure = createAction(
  '[Signup] Load VAT Failure',
  props<{ error: any }>()
);

// Update Account
export const updateAccount = createAction(
  '[Signup] Update Account'
);

export const updateAccountSuccess = createAction(
  '[Signup] Update Account Success',
  props<{ identity: CreateTokenOutput['identity'] }>()
);

export const updateAccountFailure = createAction(
  '[Signup] Update Account Failure',
  props<{ error: any }>()
);

// Update Billing Informations
export const updateBillingInformationsAndSubscription = createAction(
  '[Signup] Update Billing Informations and subscription'
);

export const updateBillingInformations = createAction(
  '[Signup] Update Billing Informations'
);

export const updateBillingInformationsSuccess = createAction(
  '[Signup] Update Billing Informations Success',
  props<{ billingInformations: any }>()
);

export const updateBillingInformationsFailure = createAction(
  '[Signup] Update Billing Informations Failure',
  props<{ error: any }>()
);

export const updateSubscription = createAction(
  '[Signup] Update Subscription'
);

export const updateSubscriptionSuccess = createAction(
  '[Signup] Update Subscription Success',
  props<{ subscription: any }>()
);

export const updateSubscriptionFailure = createAction(
  '[Signup] Update Subscription Failure',
  props<{ error: any }>()
);

//Signup
export const signup = createAction(
  '[Signup] Signup',
);

export const signupSuccess = createAction(
  '[Signup] Signup Success',
);

export const signupFailure = createAction(
  '[Signup] Signup Failure',
  props<{ error: any }>()
);

export const loadPlan = createAction(
  '[Signup] Load Plan',
);

export const loadPlanSuccess = createAction(
  '[Signup] Load Plan Success',
  props<{ plan: Plan }>()
);

export const loadPlanFailure = createAction(
  '[Signup] Load Plan Failure',
  props<{ error: any }>()
);
