
import { TeamRole } from '../../core/accessManagement/roles';
import { Roles } from '../../core/accessManagement/config/roles';
import { Policy } from '../models/policy';
import { Role } from '../models/role';

const sortKeys = (x) => {
    if (Array.isArray(x) && typeof x[0] === 'object') { // re-order array of object
        if (x[0].description) {
            const nested = x.sort((a, b) => a.description.localeCompare(b.description));
            return nested.map(sortKeys);
        }
        if (x[0].effect) {
            const nested = x.sort((a, b) => a.effect.localeCompare(b.effect));
            return nested.map(sortKeys);
        }
    }
    if (Array.isArray(x)) {
        return x.sort();
    }
    if (typeof x !== 'object' || !x) {
        return x;
    }

    return Object.keys(x).sort().reduce((o, k) => ({ ...o, [k]: sortKeys(x[k]) }), {});
};

const roleNeedUpdate = (remoteObj, localObj): { policiesToAttach: Policy[], policiesToDetach: Policy[], roleToUpdate: Role } => {
    const registeredPolicies = remoteObj.policies.map(p => p.name);
    const localPolicies = localObj.policies.map(p => p.name);
    const policiesToAttach = localObj.policies.filter(p => registeredPolicies.indexOf(p.name) === -1);
    const policiesToDetach = remoteObj.policies.filter(p => localPolicies.indexOf(p.name) === -1);

    const updates: { policiesToAttach: any[], policiesToDetach: any[], roleToUpdate: any } = { policiesToAttach: [], policiesToDetach: [], roleToUpdate: undefined };

    if (policiesToAttach.length || policiesToDetach.length) {
        updates.policiesToAttach = policiesToAttach;
        updates.policiesToDetach = policiesToDetach;
    }
    // get role withtout policies
    const { policies, ...roleToUpdate } = localObj;
    const { policies: remotePolicies, ...remoteRole } = remoteObj;

    const remote = sortKeys(JSON.parse(JSON.stringify(remoteRole)));
    const local = sortKeys(JSON.parse(JSON.stringify(roleToUpdate)));
    if (JSON.stringify(remote) !== JSON.stringify(local)) {
        updates.roleToUpdate = roleToUpdate;
    }

    return updates;
};

const policyNeedUpdate = (remoteObj, localObj) => {
    // if statement length is different
    if (remoteObj.statement && remoteObj.statement.length !== localObj.statement.length) {
        return true;
    }

    // if policies length is different
    if (remoteObj.policies && remoteObj.policies.length !== localObj.policies.length) {
        return true;
    }
    // need to check each statement
    const remote = sortKeys(JSON.parse(JSON.stringify(remoteObj)));
    const local = sortKeys(JSON.parse(JSON.stringify(localObj)));
    if (JSON.stringify(remote) !== JSON.stringify(local)) {
        return true;
    }

    return false;
};

const computeLocalRolesAndPolicies = (teams, account, region) => {
    let localRoles = [];
    let localPolicies = [];
    // computing local team Roles && Policies
    teams.forEach(team => {
        localRoles = [...localRoles, ...Object.keys(Roles.teamRoles)
            .map(roleName => {
                // dans les params je pense pas que account region soient necessaire
                const teamRole = new TeamRole({ roleName, role: Roles.teamRoles[roleName] }, { team: team.id, domain: team.domain });
                delete teamRole.shortName;
                return teamRole;
            })];
    });
    localPolicies = localRoles.map(role => role.policies)
        .reduce((it, policies) => {
            const dic = it.map(p => p.name);
            const toAdd = [];
            policies.map(p => {
                if (dic.indexOf(p.name) === -1) {
                    toAdd.push(p);
                }
            });
            return [...toAdd, ...it];
        }, []).sort((a, b) => a.description.localeCompare(b.description));
    return { localRoles, localPolicies };
};

const compareRoles = (remoteRoles, localRoles) => {
    ////// ROLES /////////
    let countRoles = 0;
    let usedRoles = 0;
    let unusedRolesCount = 0;
    let rolesToDeleteCount = 0;
    const RolesToCreate = [];
    const RolesToUpdate = [];
    const RolesToDelete = [];

    // find outdated roles on the remote
    const localUpdatedRoles = localRoles.map(rRole => rRole.name);
    remoteRoles.forEach(rRole => {
        const isOldRole = localUpdatedRoles.indexOf(rRole.name) === -1;
        if (isOldRole) {
            // console.log('Found role to delete : ', rRole.name);
            RolesToDelete.push(rRole);
            if (rRole.policies.length) {
                RolesToUpdate.push({ role: rRole, policiesToAttach: [], policiesToDetach: rRole.policies });
            }

            rolesToDeleteCount++;
        }
    });

    localRoles.forEach(lRole => {
        // find new local roles that have not been pushed
        const rRole = remoteRoles.find(rRole => rRole.name === lRole.name);
        if (rRole) {
            usedRoles++;
            const updatesNeeded = roleNeedUpdate(rRole, lRole);
            if (updatesNeeded?.policiesToAttach?.length || updatesNeeded?.policiesToDetach?.length || updatesNeeded?.roleToUpdate) {
                countRoles++;
                RolesToUpdate.push({ role: lRole, ...updatesNeeded });
                // console.log('Found diff in role ', lRole.name);
            }
        } else {
            // console.log('Unused local role, need to create', lRole.name);
            RolesToCreate.push(lRole);
            RolesToUpdate.push({ role: lRole, policiesToAttach: lRole.policies, policiesToDetach: [] });
            unusedRolesCount++;
        }
    });



    return {
        countRoles,
        usedRoles,
        unusedRolesCount,
        rolesToDeleteCount,
        RolesToCreate,
        RolesToUpdate,
        RolesToDelete
    };
};

const comparePolicies = (remotePolicies, localPolicies) => {
    let policiesToUpdate = 0;
    let usedPolicies = 0;
    let unusedPoliciesCount = 0;
    let policiesToCreateCount = 0;
    let policiesToDeleteCount = 0;
    const PoliciesToUpdate = [];
    const PoliciesToCreate = [];
    const PoliciesToDelete = [];
    const existingPolicies = remotePolicies.map(rPolicy => rPolicy.name);
    const localUpdatedPolicies = localPolicies.map(rPolicy => rPolicy.name);

    // find Old policies
    remotePolicies.forEach(rPolicy => {
        const isOldPolicy = localUpdatedPolicies.indexOf(rPolicy.name) === -1;
        if (isOldPolicy) {
            // console.log('Found policy to delete : ', rPolicy.name);
            PoliciesToDelete.push(rPolicy);
            policiesToDeleteCount++;
        }
    });

    // Process policies to create / update
    localPolicies.forEach(lPolicy => {
        const rPolicy = remotePolicies.find(rPolicy => rPolicy.name === lPolicy.name);
        const isNewPolicy = existingPolicies.indexOf(lPolicy.name) === -1;
        if (isNewPolicy) {
            PoliciesToCreate.push(lPolicy);
            // console.log('Found policy to create : ', lPolicy.name);
            policiesToCreateCount++;
        } else if (rPolicy) {
            usedPolicies++;
            const foundDiff = policyNeedUpdate(rPolicy, lPolicy);
            // console.log("YES policy need update", foundDiff);
            if (foundDiff) {
                policiesToUpdate++;
                PoliciesToUpdate.push(lPolicy);
            }
        } else {
            unusedPoliciesCount++;
        }
    });


    return {
        policiesToUpdate,
        usedPolicies,
        unusedPoliciesCount,
        policiesToCreateCount,
        policiesToDeleteCount,
        PoliciesToUpdate,
        PoliciesToCreate,
        PoliciesToDelete
    };

};

export const computePendingRolesAndPoliciesUpdates = ({ remotePolicies, remoteRoles }, { account, teams, region }) => {

    let logs = '';
    let status = 'need update';
    let result = '';

    const {
        localRoles,
        localPolicies
    } = computeLocalRolesAndPolicies(teams, account, region);

    // ////// ROLES /////////
    const {
        countRoles,
        usedRoles,
        unusedRolesCount,
        rolesToDeleteCount,
        RolesToCreate,
        RolesToUpdate,
        RolesToDelete,
    } = compareRoles(remoteRoles, localRoles);

    logs += `Found ${localRoles.length} roles that should be used by Roles \r\n`;
    logs += `Found ${usedRoles} roles actually used by Roles (match with remote roles)\r\n`;
    logs += `Found ${countRoles} differed  roles\r\n`;
    logs += `Found ${unusedRolesCount} unused local roles\r\n`;
    // ////// POLICIES //////
    const {
        policiesToUpdate,
        usedPolicies,
        unusedPoliciesCount,
        policiesToCreateCount,
        policiesToDeleteCount,
        PoliciesToUpdate,
        PoliciesToCreate,
        PoliciesToDelete,
    } = comparePolicies(remotePolicies, localPolicies);

    logs += `Found ${localPolicies.length} policies that should be used by Roles\r\n`;
    logs += `Found ${usedPolicies} policies actually used by Roles (match with remote policies)\r\n`;
    logs += `Found ${policiesToUpdate} differed  policies\r\n`;
    logs += `Found ${policiesToDeleteCount} old policies\r\n`;
    logs += `Found ${policiesToCreateCount} new  policies\r\n`;
    logs += `Found ${unusedPoliciesCount} unused local policies\r\n`;
    logs += `Found ${rolesToDeleteCount} unused local roles\r\n`;
    logs += `######## ----- SUMMARY------ #########\r\n`;

    if ([rolesToDeleteCount, unusedRolesCount, policiesToDeleteCount, policiesToCreateCount, policiesToUpdate, countRoles].some(v => v !== 0)) {
        logs += `Should create and attach ${unusedRolesCount}\r\n`;
        logs += `Should Delete ${rolesToDeleteCount} roles to reflect local changes\r\n`;
        logs += `Should Delete ${policiesToDeleteCount} policies to reflect local changes\r\n`;
        logs += `should Create ${policiesToCreateCount} new policies to reflect local changes\r\n`;
        logs += `Should Patch ${policiesToUpdate} policies to reflect local changes\r\n`;
        logs += `Should Patch ${countRoles} roles to reflect local changes\r\n`;
        result = 'need to update';
    } else {
        logs += `Nothing to do\r\n`;
        result = `Nothing to do\r\n`;
        status = 'up to date';
    }

    return {
        name: 'Update Policies & Roles',
        status,
        result,
        type: 'policyRoleUpdate',
        logs,
        tasks: { PoliciesToCreate, PoliciesToUpdate, RolesToDelete, RolesToCreate, RolesToUpdate, PoliciesToDelete }
    };
};
