import { createAction, props } from '@ngrx/store';

export const decodeMessage = createAction(
  '[RenewPassword] Decode Message user',
  props<{ message: string }>()
);

export const decodeMessageSuccess = createAction(
  '[RenewPassword] Decode Message success user',
  props<{ decodedTokenMessage: any }>()
);

export const decodeMessageFailure = createAction(
  '[RenewPassword] Decode Message failure user',
  props<{ error: string }>()
);

export const renewPassword = createAction(
  '[RenewPassword] RenewPassword user',
  props<{ password: string }>()
);

export const renewPasswordSuccess = createAction(
  '[RenewPassword] RenewPassword success user',
);

export const renewTokenExpired = createAction(
  '[RenewPassword] RenewPassword token expired user',
  props<{ error: string }>()
);

export const renewPasswordFailure = createAction(
  '[RenewPassword] RenewPassword failure user',
  props<{ error: string }>()
);




