import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MomentModule } from 'ngx-moment';
import { NgClickOutsideDirective } from 'ng-click-outside2';
import { SmashManufactureComponent } from './components/smash-manufacture/smash-manufacture.component'; // onlyin viewer
import { SmashCountdownComponent } from './components/smash-countdown/smash-countdown.component'; // onlyin viewer
import { SmashSpinnerComponent } from './components/smash-spinner/smash-spinner.component';
import { SmashLoadingSpinnerComponent } from './components/smash-loading-spinner/smash-loading-spinner.component';
import { SmashBackBtnComponent } from './components/smash-back-btn/smash-back-btn.component';
import { SmashFileDropComponent } from './components/smash-file-drop/smash-file-drop.component';
import { SmashIframeComponent } from './components/smash-iframe/smash-iframe.component';
import { SmashTooltipComponent } from './components/smash-tooltip/smash-tooltip.component';

import { BackgroundImageDirective } from './directives/background-image/background-image.directive';
import { ConnectFormDirective } from './directives/connect-form/connect-form.directive';
import { PreventDoubleClickDirective } from './directives/prevent-double-click/prevent-double-click.directive';
import { ElementProportionalResizeDirective } from './directives/element-proportional-resize/element-proportional-resize.directive';
import { ElementResizeDirective } from './directives/element-resize/element-resize.directive';

import { BytesPipe } from './pipes/bytes.pipe';
import { CleanNumberPipe } from './pipes/clean-number.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { SafeUrlPipe } from './pipes/safe-url.pipe';
import { CountdownPipe } from './pipes/countdown.pipe';
import { SmashTooltipDirective } from './directives/smash-tooltip/smash-tooltip.directive';
import { PriceFormaterPipe } from './pipes/price-formater.pipe';
import { PlanDurationInMonthPipe } from './pipes/plan-duration-month.pipe';

import { DateLocaleFilter } from './pipes/date-locale.pipe';
import { ArraySorterPipe } from './pipes/array-sorter.pipe';
import { FirstLetterUppercasePipe } from './pipes/first-letter-uppercase.pipe'; // Used by SmahsDataTable
import { TruncatePipe } from './pipes/truncate.pipe';
import { BitsPipe } from './pipes/bits.pipe';
import { SlugifyPipe } from './pipes/slugify.pipe';
import { CustomDurationPipe } from './pipes/custom-duration.pipe';
import { SmashCopyButtonComponent } from './components/smash-copy-button/smash-copy-button.component';
import { SmashTrustpilotComponent } from './components/smash-trustpilot/smash-trustpilot.component';
import { SmashMfaAuthenticatorSetupComponent } from './components/smash-mfa-authenticator-setup/smash-mfa-authenticator-setup.component';
import { SmashButtonComponent } from './components/smash-button/smash-button.component';

@NgModule({
  declarations: [
    /// DIRECTIVES
    SmashSpinnerComponent,
    PreventDoubleClickDirective,
    BackgroundImageDirective,
    ElementProportionalResizeDirective, // used by previewer-customization
    ElementResizeDirective, // used by previewer-customization
    ConnectFormDirective,
    SmashTooltipDirective,
    /// PIPES
    SafeUrlPipe,
    SafeHtmlPipe,
    CleanNumberPipe,
    BytesPipe,
    CountdownPipe,
    PriceFormaterPipe,
    PlanDurationInMonthPipe,
    ArraySorterPipe,
    BitsPipe,
    TruncatePipe,
    DateLocaleFilter,
    FirstLetterUppercasePipe,
    SlugifyPipe,
    CustomDurationPipe,
    /// COMPONENTS
    SmashLoadingSpinnerComponent,
    SmashBackBtnComponent,
    SmashManufactureComponent, // onlyin viewer
    SmashCountdownComponent, // onlyin viewer
    SmashFileDropComponent,
    SmashIframeComponent,
    SmashTooltipComponent,
    SmashCopyButtonComponent,
    SmashTrustpilotComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    MomentModule,
    NgClickOutsideDirective,
    SmashMfaAuthenticatorSetupComponent,
    SmashButtonComponent,
  ],
  exports: [
    TranslateModule,
    MomentModule,
    /// DIRECTIVES
    ConnectFormDirective,
    BackgroundImageDirective,
    SmashTooltipDirective,
    PreventDoubleClickDirective,
    ElementProportionalResizeDirective, // used by previewer-customization
    ElementResizeDirective,  // used by previewer-customization
    /// PIPES
    SafeHtmlPipe,
    SafeUrlPipe,
    CleanNumberPipe,
    BytesPipe,
    CountdownPipe,
    PriceFormaterPipe,
    PlanDurationInMonthPipe,
    BitsPipe,
    ArraySorterPipe, // used by DataTable
    TruncatePipe, // used by DataTable
    DateLocaleFilter, // used by DataTable
    FirstLetterUppercasePipe,
    SlugifyPipe,
    CustomDurationPipe,
    /// COMPONENTS
    SmashSpinnerComponent,
    SmashLoadingSpinnerComponent,
    SmashBackBtnComponent, // only used in admin
    SmashCountdownComponent,
    SmashManufactureComponent,
    SmashFileDropComponent,
    SmashIframeComponent,
    SmashTooltipComponent,
    SmashCopyButtonComponent,
    SmashTrustpilotComponent,
    SmashMfaAuthenticatorSetupComponent,
    SmashButtonComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    TranslateService,
    BytesPipe,
    CountdownPipe,
    ArraySorterPipe,
    SlugifyPipe,
    CustomDurationPipe,
  ]
})
export class SharedModule { }
