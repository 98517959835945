import { createAction, props } from '@ngrx/store';

export const forgotPassword = createAction(
  '[ForgotPassword] ForgotPassword user',
  props<{ email: string }>()
);

export const forgotPasswordSuccess = createAction(
  '[ForgotPassword] ForgotPassword success user',
);

export const forgotPasswordFailure = createAction(
  '[ForgotPassword] ForgotPassword failure user',
  props<{ error: any }>()
);

export const resetForgot = createAction(
  '[ForgotPassword] Reset Forgot Section',
);



