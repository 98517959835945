<button [disabled]="disabled" [type]="type" [ngClass]="{
    'btn-new' : device === 'desktop',
    'button-mobile': device === 'mobile',
    'red': color === 'red', 
    'white': color === 'white',
}" [style]="{width: width, height: height}" (click)="handleClick()">
    <ng-container *ngIf="loading; else notLoading">
        <div class="loading-spinner-container">
            <div class="button-loading-spinner"></div>
        </div>
    </ng-container>
    <ng-template #notLoading>
        {{ text }}
    </ng-template>
</button>