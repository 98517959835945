import { createAction, props } from '@ngrx/store';
import { CreateTeamDropboxOutput, GetDropboxPreviewOutput } from '@smash-sdk/transfer/ejs/01-2024';

export const CreateTeamDropbox = createAction(
  '[Dropbox] Create Team Dropbox',
  props<{ teamId: string }>()
);

export const CreateTeamDropboxSuccess = createAction(
  '[Dropbox] Create Team Dropbox Success',
  props<{ dropbox: CreateTeamDropboxOutput['dropbox'] }>()
);

export const CreateTeamDropboxFailure = createAction(
  '[Dropbox] Create Team Dropbox Failure',
  props<{ error: any }>()
);

export const GetDropboxPreview = createAction(
  '[Dropbox] Get Dropbox Preview',
  props<{ dropboxId: string }>()
);

export const GetDropboxPreviewSuccess = createAction(
  '[Dropbox] Get Dropbox Preview Success',
  props<{ dropbox: GetDropboxPreviewOutput['dropbox'] }>()
);

export const GetDropboxPreviewFailure = createAction(
  '[Dropbox] Get Dropbox Preview Failure',
  props<{ error: any }>()
);

export const ResetDropbox = createAction(
  '[Dropbox] Reset Dropbox'
);
