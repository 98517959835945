<div class="mfa-authenticator-setup-container">
    <ng-container *ngIf="applicationSecretKeyDisplayed">
        <div class="application-secret-key" translate>{{ secret }}</div>
        <div class="application-secret-key-label" (click)="toggleSecretKey()" translate>
            MFA_CHALLENGE_APPLICATION_SHOW_QR_CODE_LABEL
        </div>
    </ng-container>
    <ng-container *ngIf="!applicationSecretKeyDisplayed">
        <div class="qrcode-container">
            <qrcode [qrdata]="authenticatorUrl"></qrcode>
        </div>
        <div class="application-secret-key-label" (click)="toggleSecretKey()" translate>
            MFA_CHALLENGE_APPLICATION_SHOW_SECRET_KEY_LABEL</div>
    </ng-container>
</div>