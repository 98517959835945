import { FileSystemMetadata } from '@app/shared/components/smash-file-drop/interfaces';
import { Transfer } from '@app/shared/models/transfer';
import { createAction, props } from '@ngrx/store';
import { UploaderError, UploaderStatus } from '@smash-sdk/uploader';
import { UploadCompleteOutput } from '@smash-sdk/uploader/dist/es/interface/Output';
import { RawFileError } from '@app/client/uploader/interfaces/file';
import { CloudfrontError } from '@app/shared/helpers/cloudfront';
import { GetUsageOutput } from '@smash-sdk/gnitekram';

export const SetProgress = createAction('[Uploader] Set Upload Progress', props<{ progress: number }>());
export const SetUploadSpeed = createAction('[Uploader] Set Upload Speed', props<{ speed: number }>());
export const SetUploadedBytes = createAction('[Uploader] Set Upload Uploaded Bytes', props<{ uploadedBytes: number }>());
export const SetRemainingSize = createAction('[Uploader] Set Upload Remaining Size', props<{ remainingSize: number }>());
export const SetUploadStatus = createAction('[Uploader] Set Upload Status', props<{ status: UploaderStatus }>());

export const Upload = createAction('[Uploader] Upload');
export const UploadSuccess = createAction('[Uploader] Upload Success', props<{ transfer: UploadCompleteOutput['transfer'] }>());
export const UploadFailure = createAction('[Uploader] Upload Failure', props<{ error: CloudfrontError<UploaderError> }>());

export const UpdateTransferSuccess = createAction('[Uploader] Update Transfer Success', props<{ transfer: Transfer }>());
export const UpdateTransferFailure = createAction('[Uploader] Update Transfer Failure', props<{ error: any }>());

export const AddRawFilesSuccess = createAction('[Uploader] Add Raw Files Success', props<{ files: FileSystemMetadata[] }>());
export const AddRawFilesFailure = createAction('[Uploader] Add Raw Files Failure', props<{ error: RawFileError }>());

export const ResetRawFilesError = createAction('[Uploader] Reset Raw Files Error');

export const RemoveRawFile = createAction('[Uploader] Remove File', props<{ file: FileSystemMetadata }>());
export const RemoveRawFiles = createAction('[Uploader] Remove Files', props<{ files: FileSystemMetadata[] }>());

export const CancelUpload = createAction('[Uploader] Cancel Upload');
export const CancelUploadSuccess = createAction('[Uploader] Cancel Upload Success');
export const ResetUpload = createAction('[Uploader] Reset Upload');
export const ResetUploadSuccess = createAction('[Uploader] Reset Upload Success');

export const UploadQueued = createAction('[Uploader] Upload Queued', props<{ queue: number, queuedUntil: string }>());
export const UploadQueueFinished = createAction('[Uploader] Upload Queue Finished');

export const UpdateAndResumeUpload = createAction('[Uploader] Update and resume upload');
export const UpdateAndResumeUploadSuccess = createAction('[Uploader] Update and resume upload Success');
export const UpdateAndResumeUploadFailure = createAction('[Uploader] Update and resume upload Failure', props<{ error: any }>());

export const CheckFirstTimeSender = createAction('[Uploader] Check First Time Sender', props<{ username: string, transferId: string }>());
export const CheckFirstTimeSenderSuccess = createAction('[Uploader] Check First Time Sender Success', props<{ usage: GetUsageOutput['usage'] }>());
export const CheckFirstTimeSenderFailure = createAction('[Uploader] Check First Time Sender Failure', props<{ error: any }>());