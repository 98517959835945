import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-smash-button',
  standalone: true,
  imports: [
    CommonModule  
  ],
  templateUrl: './smash-button.component.html',
  styleUrl: './smash-button.component.scss'
})
export class SmashButtonComponent {
  @Input() type: 'button' | 'submit' = 'button';
  @Input() device: 'desktop' | 'mobile' = 'desktop';
  @Input() disabled: boolean;
  @Input() loading: boolean;
  @Input() color: 'red' | 'white' = 'red';
  @Input() text: string;
  @Input() width: string;
  @Input() height: string;

  @Output() onClick = new EventEmitter();

  constructor() { }

  handleClick() {
    this.onClick.emit();
  }
}
