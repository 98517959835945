import { DomainActions } from '@app/core/actions';
import { Action, createReducer, on } from '@ngrx/store';
import { GetRecordOutput } from '@smash-sdk/domain/ejs/01-2024';
import { WorkflowActions } from '../actions';


export const workflowFeatureKey = 'workflow';

export interface State {
  step: string;
  mode: GetRecordOutput['record']['options']['transferMode']['default'];
}

export const initialState: State = {
  step: 'LANDING',
  mode: undefined,
};

const workflowReducer = createReducer(
  initialState,
  on(WorkflowActions.resetStep, (state) => ({
    ...state,
    step: initialState.step
  })),
  on(WorkflowActions.updateStep, (state, { step }) => ({
    ...state,
    step
  })),
  on(WorkflowActions.updateMode, (state, { mode }) => ({
    ...state,
    mode
  })),
  on(DomainActions.loadDomainSuccess, (state, { domain }) => ({
    ...state,
    mode: domain.options?.transferMode?.default || initialState.mode,
  })),
);

export function reducer(state: State | undefined, action: Action) {
  return workflowReducer(state, action);
}

export const getStep = (state: State) => state.step;
export const getMode = (state: State) => state.mode;
