import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SigninActions } from '@app/client/signin/store/actions';
import { SubscriptionActions } from '@app/client/signup/subscription/actions';
import * as fromUploader from '@app/client/uploader/store/reducers';
import { GoogleTagManagerService } from '@app/core/lib/google-tag-manager/angular-google-tag-manager.service';
import * as fromRoot from '@app/reducers';
import { Account } from '@app/shared/models/account';
import { BillingInformations } from '@app/shared/models/billingInformations';
import { Team } from '@app/shared/models/team';
import { User } from '@app/shared/models/user';
import { genericRetry } from '@app/shared/operators/genericRetry';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { errors as errorsBilling2021, Billing as Billing072021 } from '@smash-sdk/billing/ejs/07-2021/billing';
import { errors as errorsBilling2024, Billing as Billing012024 } from '@smash-sdk/billing/ejs/01-2024/billing';
import { config } from '@smash-sdk/core';
import { Iam, errors as IamSdkErrors } from '@smash-sdk/iam';
import { Observable, defer, forkJoin, from, of } from 'rxjs';
import { catchError, concatMap, defaultIfEmpty, exhaustMap, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { Constant } from 'src/constant';
import { AccountActions, EnvironmentActions } from '../actions';
import { CookiesService } from '../services/cookies.service';
import { Discovery } from '@smash-sdk/discovery/ejs/02-2023/discovery';
import { Domain } from '@smash-sdk/domain/ejs/01-2024';
import { Directory } from '@smash-sdk/directory/ejs/11-2023';
import GetPlanError from '@smash-sdk/billing/ejs/01-2024/types/GetPlan/GetPlanError';
import GetSubscriptionError from '@smash-sdk/billing/ejs/07-2021/types/GetSubscription/GetSubscriptionError';
import ListSubscriptionLimitsError from '@smash-sdk/billing/ejs/07-2021/types/ListSubscriptionLimits/ListSubscriptionLimitsError';
import { TokenService } from '../services/token.service';
import { IamService } from '../services/iam.service';

@Injectable()
export class AccountEffects {

  constructor(
    private actions$: Actions,
    private tokenService: TokenService,
    private iamService: IamService,
    private cookieService: CookiesService,
    private gtmService: GoogleTagManagerService,
    private router: Router,
    private readonly store$: Store<fromRoot.State & fromUploader.State>,
    private cookiesService: CookiesService,
  ) { }

  createAnonymousUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountActions.createUserAnonymous),
      switchMap(() => {
        return this.iamService.createAccount().pipe(
          map(({ account }) => {
            const identity = account;
            this.cookieService.setCookie('identity', identity);
            config.setToken(identity.token.token);
            return AccountActions.createUserAnonymousSuccess({ identity });
          }),
          catchError((error: any) => of(AccountActions.createUserAnonymousFailure({ error: error.message })))
        );
      })
    )
  );

  refreshToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountActions.refreshToken),
      withLatestFrom(
        this.store$.select(fromRoot.getRefreshToken),
      ),
      switchMap(([action, refreshToken]) => {
        if (refreshToken?.token) {
          return this.iamService.renewToken({ refreshToken: refreshToken.token }).pipe(
            map((res: any) => {
              const identity = res.identity;
              this.cookieService.setCookie('identity', identity);
              config.setToken(identity.token.token);
              return AccountActions.refreshTokenSuccess({ identity });
            }),
            catchError((error: any) =>
              of(AccountActions.refreshTokenFailure({ error: error.message }))
            )
          );
        } else {
          return of(AccountActions.refreshTokenFailure({ error: 'No refresh token found' }));
        }
      }),
    )
  );

  refreshTokenFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountActions.refreshTokenFailure),
      withLatestFrom(
        this.store$.select(fromRoot.getActiveIdentityUpdating),
      ),
      tap(([action, activeIdentityUpdating]) => {
        if (!activeIdentityUpdating) {
          this.router.navigateByUrl('/signout');
        }
      })
    ),
    { dispatch: false }
  );

  loadBillingInformations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountActions.loadBillingInformations),
      switchMap(() => {
        const billingSdk = new Billing012024();
        return from(billingSdk.getInformations()).pipe(
          map(({ informations }) => {
            return AccountActions.loadBillingInformationsSuccess({ billingInformations: informations });
          }),
          catchError((error: HttpErrorResponse) => {
            if (error instanceof errorsBilling2024.GetInformationsError.NotFoundError) {
              return of(AccountActions.loadBillingInformationsSuccess({ billingInformations: null }));
            } else {
              return of(AccountActions.loadBillingInformationsFailure({ error: error.message }));
            }
          })
        );
      })
    )
  );

  loadSubscription$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountActions.loadSubscription),
      switchMap(() => {
        const billingSdk = new Billing072021();
        return defer(() => Promise.all([billingSdk.getSubscription(), billingSdk.listSubscriptionLimits()])).pipe(
          genericRetry({
            retryConfig: [
              ...Constant.NetworkErrorsRetryConfiguration,
              { error: GetSubscriptionError.InternalServerError, maxRetry: 10, exponentialBackoff: true },
              { error: GetSubscriptionError.GatewayTimeoutError, maxRetry: 10, exponentialBackoff: true },
              { error: GetSubscriptionError.BadGatewayError, maxRetry: 10, exponentialBackoff: true },
              { error: ListSubscriptionLimitsError.InternalServerError, maxRetry: 10, exponentialBackoff: true },
              { error: ListSubscriptionLimitsError.GatewayTimeoutError, maxRetry: 10, exponentialBackoff: true },
              { error: ListSubscriptionLimitsError.BadGatewayError, maxRetry: 10, exponentialBackoff: true },
            ]
          }),
          mergeMap(([{ subscription }, { limits }]) => {
            return [AccountActions.loadSubscriptionSuccess({ subscription, limits })];
          }),
          catchError((error: HttpErrorResponse) => {
            if (error instanceof errorsBilling2021.GetSubscriptionError.NotFoundError
              || error instanceof errorsBilling2021.GetSubscriptionError.ForbiddenError
              || error instanceof errorsBilling2021.GetSubscriptionError.UnauthorizedError) {
              return of(AccountActions.loadSubscriptionSuccess({ subscription: null }));
            } else {
              return of(AccountActions.loadSubscriptionFailure({ error: error.message }));
            }
          }
          )
        );
      })
    )
  );


  // This effects is now calling  the same endpoint version as loadSubscription
  // so is it still necessary ?? we should simplify this
  loadSubscriptionWithPlan$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountActions.loadSubscriptionWithPlan),
      exhaustMap(() => {
        const billingSdk = new Billing072021();
        return defer(() => Promise.all([billingSdk.getSubscription(), billingSdk.listSubscriptionLimits()])).pipe(
          genericRetry({
            retryConfig: [
              ...Constant.NetworkErrorsRetryConfiguration,
              { error: GetSubscriptionError.InternalServerError, maxRetry: 10, exponentialBackoff: true },
              { error: GetSubscriptionError.GatewayTimeoutError, maxRetry: 10, exponentialBackoff: true },
              { error: GetSubscriptionError.BadGatewayError, maxRetry: 10, exponentialBackoff: true },
              { error: ListSubscriptionLimitsError.InternalServerError, maxRetry: 10, exponentialBackoff: true },
              { error: ListSubscriptionLimitsError.GatewayTimeoutError, maxRetry: 10, exponentialBackoff: true },
              { error: ListSubscriptionLimitsError.BadGatewayError, maxRetry: 10, exponentialBackoff: true },
            ]
          }),
          mergeMap(([{ subscription }, { limits }]) => {
            return [AccountActions.loadSubscriptionWithPlanSuccess({ subscription, limits })];
          }),
          catchError((error: HttpErrorResponse) => {
            if (error instanceof errorsBilling2021.GetSubscriptionError.NotFoundError
              || error instanceof errorsBilling2021.GetSubscriptionError.UnauthorizedError) {
              return of(AccountActions.loadSubscriptionWithPlanSuccess({ subscription: null }));
            } else {
              return of(AccountActions.loadSubscriptionWithPlanFailure({ error: error.message }));
            }
          }
          )
        );
      })
    )
  );

  loadPlan$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountActions.loadPlan),
      withLatestFrom(
        this.store$.select(fromRoot.getSelectedLanguage),
      ),
      switchMap(([action, language]) => {
        const { subscription } = action;
        const billingSdk = new Billing012024();
        if (subscription?.plan) {
          return from(billingSdk.getPlan(subscription.plan)).pipe(
            concatMap(({ plan }: any) => {
              return [
                AccountActions.loadSubscriptionSuccess({ subscription }),
                AccountActions.loadPlanSuccess({ plan })];
            }),
            catchError((error: HttpErrorResponse) => {
              if (error instanceof GetPlanError.NotFoundError) {
                return of(AccountActions.loadPlanSuccess({ plan: null }));
              } else {
                return of(AccountActions.loadPlanFailure({ error: error.message }));
              }
            }
            )
          );
        } else {
          return [
            AccountActions.loadSubscriptionSuccess({ subscription }),
            AccountActions.loadPlanFailure({ error: 'No plan in subscription.' })
          ];
        }
      })
    )
  );

  loadPlanGroupFromSubscription$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountActions.loadPlanGroupFromSubscription),
      switchMap(() => {
        const billingSdk = new Billing072021();
        return from(billingSdk.getSubscription()).pipe(
          mergeMap((res) => {
            const planId = res.subscription?.plan.id;
            if (planId) {
              return from(billingSdk.getPlanGroup({ planId })).pipe(
                mergeMap((res) => {
                  const plans = res.group.plans;
                  return [SubscriptionActions.loadPlansSuccess({ plans }), AccountActions.loadPlanGroupFromSubscriptionSuccess({ planGroup: plans })];
                }),
                catchError(error =>
                  of(AccountActions.loadPlanGroupFromSubscriptionFailure({ error: error.message }))
                )
              );
            }
          }),
          catchError((error: any) =>
            of(AccountActions.loadPlanGroupFromSubscriptionFailure({ error: error.message }))
          )
        );
      })
    )
  );

  signOut$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountActions.signOut),
      map((_) => {
        this.cookieService.removeCookie('identity');
        return AccountActions.signOutSuccess();
      })
    ),
  );

  signOutSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountActions.signOutSuccess),
      withLatestFrom(
        this.store$.select(fromRoot.getMainDomain),
      ),
      tap(([action, main]: any) => {
        window.location.href = main + '/';
      })
    ), { dispatch: false }
  );

  signInAgain$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountActions.signInAgain),
      map((_) => {
        this.router.navigateByUrl('/signin');
        return AccountActions.signInAgainSuccess();
      })
    ),
  );

  getProfileInformation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountActions.getProfileInformation),
      switchMap((_) => {
        const identity = this.cookieService.getCookie('identity');
        const billingSdk = new Billing012024();
        const req: Observable<{ informations: BillingInformations } | { user: User }> = this.tokenService.isRoot(identity.token.token) ?
          from(billingSdk.getInformations()) : this.iamService.getUser(identity.id);
        return req.pipe(
          // FIX ME This is pretty confusing to have a map that return different objects, we should refactor this
          map(({ user, informations }: { user?: User, informations?: BillingInformations }) => {
            if (informations) {
              return AccountActions.getProfileInformationSuccess({ firstName: informations.firstName, lastName: informations.lastName, authenticationMode: user?.authenticationMode });
            } else {
              return AccountActions.getProfileInformationSuccess({ firstName: user.firstName, lastName: user.lastName, authenticationMode: user.authenticationMode });
            }
          }),
          catchError((error: HttpErrorResponse) => {
            return of(AccountActions.getProfileInformationFailure({ error }))
          })
        )
      })
    )
  )

  updateActiveIdentity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountActions.UpdateActiveIdentity),
      withLatestFrom(
        this.store$.select(fromRoot.getActiveIdentity),
      ),
      switchMap(([action, identity]) => {
        if (identity?.provisioned) {
          return [AccountActions.UpdateActiveIdentityFailure({ error: `User is managed by provisioning system, can't modify related informations` })];
        }

        const data = Object.assign({}, action.data); // action is readOnly

        if (data?.username) {
          data.username = data.username.toLowerCase();
        }

        const req: Observable<{ account: Account } | { user: User }> = this.tokenService.isRoot(identity.token.token) ? this.iamService.updateAccount(data) : this.iamService.updateUser(identity.id, data);

        return req.pipe(
          map(({ user, account }: any) => {
            if (account) {
              return AccountActions.UpdateActiveIdentitySuccess({ identity: account });
            }
            return AccountActions.UpdateActiveIdentitySuccess({ identity: user });
          }),
          catchError((error: HttpErrorResponse) => {
            return of(AccountActions.UpdateActiveIdentityFailure({ error }));
          })
        );
      })
    )
  );

  updateActiveTeam$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountActions.updateActiveTeam),
      tap((action) => {
        if (action.team) {
          this.cookieService.setCookie('lastActiveTeam', { domain: action.team.domain, team: action.team.id });
        }

      })
    ), { dispatch: false }
  );

  updateUsername$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountActions.UpdateUsername),
      withLatestFrom(
        this.store$.select(fromRoot.getActiveIdentity),
      ),
      switchMap(([action, identity]) => {
        if (identity?.provisioned) {
          return [AccountActions.UpdateUsernameFailure({ error: `User is managed by provisioning system, can't modify related informations` })];
        }

        const data = Object.assign({}, action.data); // action is readOnly

        if (data?.username) {
          data.username = data.username.toLowerCase();
        }

        const req: Observable<{ account: Account } | { user: User }> = this.tokenService.isRoot(identity.token.token) ? this.iamService.updateAccount({ username: data.username }) : this.iamService.updateUser(identity.id, { username: data.username });

        return req.pipe(
          map(({ user, account }: any) => {
            if (account) {
              return AccountActions.UpdateUsernameSuccess({ identity: account });
            }
            return AccountActions.UpdateUsernameSuccess({ identity: user });
          }),
          catchError((error: HttpErrorResponse) => {
            return of(AccountActions.UpdateUsernameFailure({ error: error.error }));
          })
        );
      })
    )
  );

  updatePassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountActions.UpdatePassword),
      withLatestFrom(
        this.store$.select(fromRoot.getActiveIdentity),
      ),
      switchMap(([action, identity]) => {
        const { currentPassword, newPassword } = action;

        return this.iamService.updatePassword({ currentPassword, newPassword }).pipe(
          concatMap(() => {
            const directorySdk = new Directory();
            return from(directorySdk.createToken({ username: identity.username, password: newPassword })).pipe(map(({ identity: newIdentity }) => {
              return AccountActions.UpdatePasswordSuccess({ identity: newIdentity });
            }));
          }),
          catchError((error: HttpErrorResponse) => {
            return of(AccountActions.UpdatePasswordFailure({ error: error.error }));
          })
        );
      })
    )
  );

  setupSsoSession$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountActions.SetupSsoSession),
      withLatestFrom(this.store$.select(fromRoot.getSelectedLanguage)),
      concatMap(([action, language]: any[]) => {
        const { identity } = action.informations;
        this.cookieService.setCookie('identity', identity);
        config.setToken(identity.token.token);
        const userLanguage = identity?.language || language;

        this.gtmService.pushTag({
          event: Constant.eventGTM.SIGNIN,
          signin_method: Constant.signinMode.sso,
        });

        const discoverySdk = new Discovery({ region: identity.region });
        return from(discoverySdk.listPublicServices()).pipe(
          concatMap((resServices) => {
            const { region } = resServices;

            return [
              EnvironmentActions.SelectLanguage({ language: userLanguage }),
              AccountActions.selectActiveIdentity({ identity }),
              SigninActions.signinSuccess({ identity, region }),
              AccountActions.SetupSsoSessionSuccess(),
            ];
          }));
      }),
      catchError((error: HttpErrorResponse) => {
        return of(AccountActions.SetupSsoSessionFailure({ error: error.error }));
      })
    )
  );

  signinSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SigninActions.signinSuccess),
      tap(() => {
        this.router.navigateByUrl('/');
      })
    ),
    { dispatch: false }
  );

  loadUserRolesAndTeams$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountActions.LoadUserRolesAndTeams),
      withLatestFrom(
        this.store$.select(fromRoot.getActiveIdentity),
      ),
      switchMap(([action, identity]) => {
        return this.iamService.listUserRolesAndTeams(identity).pipe(
          concatMap(({ roles, teams }) => {
            const reqGetDomains = teams.map(team => {
              const domainSdk = new Domain();
              return from(domainSdk.getDomain({ domainId: team.domain })).pipe(
                map(({ domain }) => ({ ...team, logo: domain.logo, background: domain.background }))
              )
            }
            );
            return forkJoin(reqGetDomains).pipe(
              defaultIfEmpty([]),
              mergeMap(teamsHydrated => {
                const isAdministrator = !!roles.find(role => role.name === Constant.administratorRoleName) || this.tokenService.isRoot(identity.token.token);
                return [AccountActions.LoadUserRolesAndTeamsSuccess({ roles, teams: teamsHydrated, isAdministrator })];
              }));
          }),
          catchError((error: any) =>
            of(AccountActions.LoadUserRolesAndTeamsFailure({ error: error.message }))
          )
        );
      }),
    )
  );

  loadUserRoles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountActions.LoadUserRoles),
      withLatestFrom(
        this.store$.select(fromRoot.getActiveIdentity),
      ),
      exhaustMap(([action, identity]) => {
        return this.iamService.listUserRoles(identity.id).pipe(
          concatMap(({ roles }) => {
            const isAdministrator = !!roles.find(role => role.name === Constant.administratorRoleName)
            return of(AccountActions.LoadUserRolesSuccess({ roles, isAdministrator }));
          }),
          catchError((error: HttpErrorResponse) => {
            if (error.status === 403) {
              return of(AccountActions.LoadUserRolesSuccess({ roles: [], isAdministrator: false }));
            }
            return of(AccountActions.LoadUserRolesFailure({ error: error.error }));
          })
        );
      })
    )
  );

  loadUserTeams$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountActions.LoadUserTeams),
      withLatestFrom(
        this.store$.select(fromRoot.getActiveIdentity),
        this.store$.select(fromRoot.getIsAdministrator),
      ),
      exhaustMap(([action, identity, isAdmin]) => {
        const reqs = this.tokenService.isRoot(identity.token.token) || isAdmin ?
          [this.iamService.listTeams()]
          : [this.iamService.listUserTeams(identity.id),
          this.iamService.listUserGroupsTeams(identity.id)
          ];
        return forkJoin(reqs).pipe(
          concatMap((batchTeams) => {
            const teams: Team[] = batchTeams.map(({ teams }) => teams.map(team => team)).flat();
            const lastActiveTeam = this.cookiesService.getCookie('lastActiveTeam');
            if (!lastActiveTeam && teams.length) {
              return [
                AccountActions.updateActiveTeam({ team: teams[0] }),
                AccountActions.LoadUserTeamsSuccess({ teams })
              ]
            } else {
              return [
                AccountActions.updateActiveTeam({ team: teams.find(team => team.id === lastActiveTeam?.team) || teams[0] }),
                AccountActions.LoadUserTeamsSuccess({ teams })
              ]
            }
          }),
          catchError((error: HttpErrorResponse) => {
            if (error.status === 403) {
              return of(AccountActions.LoadUserTeamsSuccess({ teams: [] }));
            }
            return of(AccountActions.LoadUserTeamsFailure({ error: error.error }));
          })
        );
      })
    )
  );

  getMfa$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountActions.GetMfa),
      withLatestFrom(this.store$.pipe(select(fromRoot.getActiveIdentity))),
      exhaustMap(([action, identity]) => {
        const iamSdk = new Iam();
        const isRoot = this.tokenService.isRoot(identity.token.token);
        const req = isRoot ? iamSdk.getMfa() : iamSdk.getUserMfa({ userId: identity.id });
        return defer(() => req).pipe(
          genericRetry({
            retryConfig: [
              ...Constant.NetworkErrorsRetryConfiguration,
              { error: IamSdkErrors.GetMfaError.InternalServerError, maxRetry: 10, exponentialBackoff: true },
              { error: IamSdkErrors.GetMfaError.BadGatewayError, maxRetry: 10, exponentialBackoff: true },
              { error: IamSdkErrors.GetMfaError.GatewayTimeoutError, maxRetry: 10, exponentialBackoff: true },
              { error: IamSdkErrors.GetUserMfaError.InternalServerError, maxRetry: 10, exponentialBackoff: true },
              { error: IamSdkErrors.GetUserMfaError.BadGatewayError, maxRetry: 10, exponentialBackoff: true },
              { error: IamSdkErrors.GetUserMfaError.GatewayTimeoutError, maxRetry: 10, exponentialBackoff: true },
            ]
          }),
          exhaustMap(({ mfa }) => {
            return of(AccountActions.GetMfaSuccess({ mfa }));
          }),
          catchError(error => of(AccountActions.GetMfaFailure({ error: error.message })))
        );
      }),
      catchError(error => of(AccountActions.GetMfaFailure({ error: error.message })))
    )
  );

  verifyApplicationFactor$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountActions.VerifyApplicationFactor),
      withLatestFrom(
        this.store$.pipe(select(fromRoot.getActiveIdentity)),
        this.store$.pipe(select(fromRoot.getMfaApplicationSecret))
      ),
      exhaustMap(([action, identity, secret]) => {
        const iamSdk = new Iam();
        const isRoot = this.tokenService.isRoot(identity.token.token);
        const req = isRoot ? iamSdk.verifyMfaFactor({ factorId: 'application', otp: action.otp, token: identity.token.token, secret }) : iamSdk.verifyUserMfaFactor({ userId: identity.id, factorId: 'application', otp: action.otp, token: identity.token.token, secret });
        return defer(() => req).pipe(
          genericRetry({
            retryConfig: [
              ...Constant.NetworkErrorsRetryConfiguration,
              { error: IamSdkErrors.VerifyMfaFactorError.InternalServerError, maxRetry: 10, exponentialBackoff: true },
              { error: IamSdkErrors.VerifyMfaFactorError.BadGatewayError, maxRetry: 10, exponentialBackoff: true },
              { error: IamSdkErrors.VerifyMfaFactorError.GatewayTimeoutError, maxRetry: 10, exponentialBackoff: true },
              { error: IamSdkErrors.VerifyUserMfaFactorError.InternalServerError, maxRetry: 10, exponentialBackoff: true },
              { error: IamSdkErrors.VerifyUserMfaFactorError.BadGatewayError, maxRetry: 10, exponentialBackoff: true },
              { error: IamSdkErrors.VerifyUserMfaFactorError.GatewayTimeoutError, maxRetry: 10, exponentialBackoff: true },
            ]
          }),
          exhaustMap(() => {
            return of(AccountActions.VerifyApplicationFactorSuccess());
          }),
          catchError(error => {
            if (error instanceof IamSdkErrors.VerifyMfaFactorError.MfaFactorNotAllowedError || error instanceof IamSdkErrors.VerifyUserMfaFactorError.MfaFactorNotAllowedError) {
              return of(AccountActions.VerifyApplicationFactorFailure({ error: error.name }));
            } else {
              return of(AccountActions.VerifyApplicationFactorFailure({ error: error.message }));
            }
          })
        );
      }),
      catchError(error => of(AccountActions.VerifyApplicationFactorFailure({ error: error.message })))
    )
  );

  verifyEmailFactor$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountActions.VerifyEmailFactor),
      withLatestFrom(this.store$.pipe(select(fromRoot.getActiveIdentity))),
      exhaustMap(([action, identity]) => {
        const iamSdk = new Iam();
        const isRoot = this.tokenService.isRoot(identity.token.token);
        const req = isRoot ? iamSdk.verifyMfaFactor({ factorId: 'email', otp: action.otp, token: identity.token.token }) : iamSdk.verifyUserMfaFactor({ userId: identity.id, factorId: 'email', otp: action.otp, token: identity.token.token });
        return defer(() => req).pipe(
          genericRetry({
            retryConfig: [
              ...Constant.NetworkErrorsRetryConfiguration,
              { error: IamSdkErrors.VerifyMfaFactorError.InternalServerError, maxRetry: 10, exponentialBackoff: true },
              { error: IamSdkErrors.VerifyMfaFactorError.BadGatewayError, maxRetry: 10, exponentialBackoff: true },
              { error: IamSdkErrors.VerifyMfaFactorError.GatewayTimeoutError, maxRetry: 10, exponentialBackoff: true },
              { error: IamSdkErrors.VerifyUserMfaFactorError.InternalServerError, maxRetry: 10, exponentialBackoff: true },
              { error: IamSdkErrors.VerifyUserMfaFactorError.BadGatewayError, maxRetry: 10, exponentialBackoff: true },
              { error: IamSdkErrors.VerifyUserMfaFactorError.GatewayTimeoutError, maxRetry: 10, exponentialBackoff: true },
            ]
          }),
          exhaustMap(() => {
            return of(AccountActions.VerifyEmailFactorSuccess());
          }),
          catchError(error => {
            if (error instanceof IamSdkErrors.VerifyMfaFactorError.MfaFactorNotAllowedError || error instanceof IamSdkErrors.VerifyUserMfaFactorError.MfaFactorNotAllowedError) {
              return of(AccountActions.VerifyEmailFactorFailure({ error: error.name }));
            } else {
              return of(AccountActions.VerifyEmailFactorFailure({ error: error.message }));
            }
          })
        );
      }),
      catchError(error => of(AccountActions.VerifyEmailFactorFailure({ error: error.message })))
    )
  );

  sendEmailOtp$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountActions.SendEmailOtp),
      withLatestFrom(this.store$.pipe(select(fromRoot.getActiveIdentity))),
      exhaustMap(([action, identity]) => {
        const iamSdk = new Iam();
        return defer(() => iamSdk.sendOtp({ factor: 'email', token: identity.token.token })).pipe(
          genericRetry({
            retryConfig: [
              ...Constant.NetworkErrorsRetryConfiguration,
              { error: IamSdkErrors.SendOtpError.InternalServerError, maxRetry: 10, exponentialBackoff: true },
              { error: IamSdkErrors.SendOtpError.BadGatewayError, maxRetry: 10, exponentialBackoff: true },
              { error: IamSdkErrors.SendOtpError.GatewayTimeoutError, maxRetry: 10, exponentialBackoff: true },
            ]
          }),
          exhaustMap(() => {
            return of(AccountActions.SendEmailOtpSuccess());
          }),
          catchError(error => {
            if (error instanceof IamSdkErrors.SendOtpError.MfaFactorNotAllowedError) {
              return of(AccountActions.SendEmailOtpFailure({ error: error.name }));
            } else {
              return of(AccountActions.SendEmailOtpFailure({ error: error.message }));
            }
          })
        );
      }),
      catchError(error => of(AccountActions.SendEmailOtpFailure({ error: error.message })))
    )
  );

  updateDefaultFactor$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountActions.UpdateDefaultFactor),
      withLatestFrom(
        this.store$.pipe(select(fromRoot.getActiveIdentity)),
      ),
      exhaustMap(([action, identity]) => {
        const iamSdk = new Iam();
        const isRoot = this.tokenService.isRoot(identity.token.token);
        const req = isRoot ? iamSdk.updateMfa({ defaultFactor: action.factor as 'email' | 'application' }) : iamSdk.updateUserMfa({ userId: identity.id, defaultFactor: action.factor as 'email' | 'application' });
        return defer(() => req).pipe(
          genericRetry({
            retryConfig: [
              ...Constant.NetworkErrorsRetryConfiguration,
              { error: IamSdkErrors.UpdateMfaError.InternalServerError, maxRetry: 10, exponentialBackoff: true },
              { error: IamSdkErrors.UpdateMfaError.BadGatewayError, maxRetry: 10, exponentialBackoff: true },
              { error: IamSdkErrors.UpdateMfaError.GatewayTimeoutError, maxRetry: 10, exponentialBackoff: true },
              { error: IamSdkErrors.UpdateUserMfaError.InternalServerError, maxRetry: 10, exponentialBackoff: true },
              { error: IamSdkErrors.UpdateUserMfaError.BadGatewayError, maxRetry: 10, exponentialBackoff: true },
              { error: IamSdkErrors.UpdateUserMfaError.GatewayTimeoutError, maxRetry: 10, exponentialBackoff: true },
            ]
          }),
          exhaustMap(() => {
            return of(AccountActions.UpdateDefaultFactorSuccess());
          }),
          catchError(error => {
            if (error instanceof IamSdkErrors.UpdateMfaError.MfaFactorNotVerifiedError || error instanceof IamSdkErrors.UpdateUserMfaError.MfaFactorNotVerifiedError) {
              return of(AccountActions.UpdateDefaultFactorFailure({ error: error.name }));
            } else {
              return of(AccountActions.UpdateDefaultFactorFailure({ error: error.message }));
            }
          })
        );
      }),
      catchError(error => of(AccountActions.UpdateDefaultFactorFailure({ error: error.message })))
    )
  );

  resetEmailFactor$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountActions.ResetEmailFactor),
      withLatestFrom(this.store$.pipe(select(fromRoot.getActiveIdentity))),
      exhaustMap(([action, identity]) => {
        const iamSdk = new Iam();
        const isRoot = this.tokenService.isRoot(identity.token.token);
        const req = isRoot ? iamSdk.resetMfaFactor({ factorId: 'email' }) : iamSdk.resetUserMfaFactor({ userId: identity.id, factorId: 'email' });
        return defer(() => req).pipe(
          genericRetry({
            retryConfig: [
              ...Constant.NetworkErrorsRetryConfiguration,
              { error: IamSdkErrors.ResetMfaFactorError.InternalServerError, maxRetry: 10, exponentialBackoff: true },
              { error: IamSdkErrors.ResetMfaFactorError.BadGatewayError, maxRetry: 10, exponentialBackoff: true },
              { error: IamSdkErrors.ResetMfaFactorError.GatewayTimeoutError, maxRetry: 10, exponentialBackoff: true },
              { error: IamSdkErrors.ResetUserMfaFactorError.InternalServerError, maxRetry: 10, exponentialBackoff: true },
              { error: IamSdkErrors.ResetUserMfaFactorError.BadGatewayError, maxRetry: 10, exponentialBackoff: true },
              { error: IamSdkErrors.ResetUserMfaFactorError.GatewayTimeoutError, maxRetry: 10, exponentialBackoff: true },
            ]
          }),
          exhaustMap(() => {
            return of(AccountActions.ResetEmailFactorSuccess());
          }),
          catchError(error => of(AccountActions.ResetEmailFactorFailure({ error: error.message })))
        );
      }),
      catchError(error => of(AccountActions.ResetEmailFactorFailure({ error: error.message })))
    )
  );

  resetApplicationFactor$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountActions.ResetApplicationFactor),
      withLatestFrom(this.store$.pipe(select(fromRoot.getActiveIdentity))),
      exhaustMap(([action, identity]) => {
        const iamSdk = new Iam();
        const isRoot = this.tokenService.isRoot(identity.token.token);
        const req = isRoot ? iamSdk.resetMfaFactor({ factorId: 'application' }) : iamSdk.resetUserMfaFactor({ userId: identity.id, factorId: 'application' });
        return defer(() => req).pipe(
          genericRetry({
            retryConfig: [
              ...Constant.NetworkErrorsRetryConfiguration,
              { error: IamSdkErrors.ResetMfaFactorError.InternalServerError, maxRetry: 10, exponentialBackoff: true },
              { error: IamSdkErrors.ResetMfaFactorError.BadGatewayError, maxRetry: 10, exponentialBackoff: true },
              { error: IamSdkErrors.ResetMfaFactorError.GatewayTimeoutError, maxRetry: 10, exponentialBackoff: true },
              { error: IamSdkErrors.ResetUserMfaFactorError.InternalServerError, maxRetry: 10, exponentialBackoff: true },
              { error: IamSdkErrors.ResetUserMfaFactorError.BadGatewayError, maxRetry: 10, exponentialBackoff: true },
              { error: IamSdkErrors.ResetUserMfaFactorError.GatewayTimeoutError, maxRetry: 10, exponentialBackoff: true },
            ]
          }),
          exhaustMap(() => {
            return of(AccountActions.ResetApplicationFactorSuccess());
          }),
          catchError(error => of(AccountActions.ResetApplicationFactorFailure({ error: error.message })))
        );
      }),
      catchError(error => of(AccountActions.ResetApplicationFactorFailure({ error: error.message })))
    )
  );

  createMfaFactorSecret$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountActions.CreateMfaFactorSecret),
      withLatestFrom(this.store$.pipe(select(fromRoot.getActiveIdentity))),
      exhaustMap(([action, identity]) => {
        const iamSdk = new Iam();
        const isRoot = this.tokenService.isRoot(identity.token.token);
        const { account, id } = this.tokenService.decodeToken(identity.token.token);
        const req = isRoot ? iamSdk.createMfaFactorSecret({ factorId: 'application', token: identity.token.token }) : iamSdk.createUserMfaFactorSecret({ userId: id, factorId: 'application', token: identity.token.token });
        return defer(() => req).pipe(
          genericRetry({
            retryConfig: [
              ...Constant.NetworkErrorsRetryConfiguration,
              { error: IamSdkErrors.CreateMfaFactorSecretError.InternalServerError, maxRetry: 10, exponentialBackoff: true },
              { error: IamSdkErrors.CreateMfaFactorSecretError.BadGatewayError, maxRetry: 10, exponentialBackoff: true },
              { error: IamSdkErrors.CreateMfaFactorSecretError.GatewayTimeoutError, maxRetry: 10, exponentialBackoff: true },
              { error: IamSdkErrors.CreateUserMfaFactorSecretError.InternalServerError, maxRetry: 10, exponentialBackoff: true },
              { error: IamSdkErrors.CreateUserMfaFactorSecretError.BadGatewayError, maxRetry: 10, exponentialBackoff: true },
              { error: IamSdkErrors.CreateUserMfaFactorSecretError.GatewayTimeoutError, maxRetry: 10, exponentialBackoff: true },
            ]
          }),
          map(({ application }) => AccountActions.CreateMfaFactorSecretSuccess({ secret: application.secret })),
          catchError(error => {
            if (error instanceof IamSdkErrors.CreateMfaFactorSecretError.MfaFactorNotAllowedError || error instanceof IamSdkErrors.CreateUserMfaFactorSecretError.MfaFactorNotAllowedError) {
              return of(AccountActions.CreateMfaFactorSecretFailure({ error: error.name }));
            } else {
              return of(AccountActions.CreateMfaFactorSecretFailure({ error: error.message }));
            }
          })
        );
      })
    )
  );
}



