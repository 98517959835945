import { config, Region } from '@smash-sdk/core';
import { Service } from '../models/service';

export function setConfigHosts(services: Service[]) {
    const serviceKeys = new Set(services.map(service => service.name));
    serviceKeys.forEach((key) => {
        const filteredServices = services.filter((service) => service.name === key);
        const urlDic = {} as { [key: string]: string };
        filteredServices.forEach(({ url, region }) => {
            urlDic[region] = url;
        });
        config.setHosts(key, urlDic);
    });
}

export function setRegion(region: Region) {
    config.setRegion(region);
}
