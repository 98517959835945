import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { UserCustomizationActions } from '@app/client/account/team/customization/actions';
import * as fromCustomization from '@app/client/account/team/customization/reducers/customization.reducer';
import * as fromRoot from '@app/reducers';
import { getVideoIdFromUrl } from '@app/shared/helpers/urlHelper';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { defer, forkJoin, from, of } from 'rxjs';
import { catchError, concatMap, delay, exhaustMap, map, mergeMap, retryWhen, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { Constant } from 'src/constant';
import { DomainActions } from '../actions';
import { ImageService } from '../services/image.service';
import { IamService } from '../services/iam.service';
import { formatMainDomain, hostnameIsFromMainDomain } from '@app/shared/helpers/location';
import { buildDomainSettings } from '@app/shared/helpers/domainSettings';
import { isPlatformBrowser } from '@angular/common';
import { REQUEST } from '../../../express.tokens';
import { Request } from 'express';
import { Domain } from '@app/shared/models/domain';
import { Provider } from '@app/shared/models/provider';
import { Domain as DomainSdk } from '@smash-sdk/domain/ejs/01-2024/domain';
import { Image } from '@smash-sdk/image/ejs/10-2019/image';
import { environment } from 'src/environments/environment';
import { genericRetry } from '@app/shared/operators/genericRetry';
import GetRecordError from '@smash-sdk/domain/ejs/01-2024/types/GetRecord/GetRecordError';
import ListRecordProvidersError from '@smash-sdk/domain/ejs/01-2024/types/ListRecordProviders/ListRecordProvidersError';

@Injectable()
export class DomainEffects {

  loadDomain$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DomainActions.loadDomain),
      withLatestFrom(
        this.store$.select(fromRoot.getTheme),
        this.store$.select(fromRoot.getDevice),
      ),
      exhaustMap(([action, theme, device]) => {
        // TO FIX temp init domain sdk with a host
        const domainSdk = new DomainSdk({ host: environment.domainSdk });
        const reqs: any = [
          defer(() => domainSdk.getRecord({ name: action.id })).pipe(
            genericRetry({
              retryConfig: [
                ...Constant.NetworkErrorsRetryConfiguration,
                { error: GetRecordError.InternalServerError, maxRetry: 10, exponentialBackoff: true },
                { error: GetRecordError.GatewayTimeoutError, maxRetry: 10, exponentialBackoff: true },
                { error: GetRecordError.BadGatewayError, maxRetry: 10, exponentialBackoff: true },
              ]
            })
          )
        ];
        // listRecordProvider only if necessary
        if (!hostnameIsFromMainDomain(location.hostname)) {
          reqs.push(
            defer(() => domainSdk.listRecordProviders({ name: action.id })).pipe(
              genericRetry({
                retryConfig: [
                  ...Constant.NetworkErrorsRetryConfiguration,
                  { error: ListRecordProvidersError.InternalServerError, maxRetry: 10, exponentialBackoff: true },
                  { error: ListRecordProvidersError.GatewayTimeoutError, maxRetry: 10, exponentialBackoff: true },
                  { error: ListRecordProvidersError.BadGatewayError, maxRetry: 10, exponentialBackoff: true },
                ]
              })
            )
          );
        }
        return forkJoin(reqs).pipe(
          mergeMap(([{ record }, ifProvidersRequest]: [{ record: Domain }, { providers: Provider[] }]) => {
            let domain: any;
            const providers = ifProvidersRequest ? [...ifProvidersRequest.providers] : [];
            const copyRecord = Object.assign({}, record);
            domain = {
              ...Constant.customizationFallback, // Fallback for logo and background
              ...copyRecord,
              options: buildDomainSettings(copyRecord),
            };

            return [
              DomainActions.loadDomainSuccess({ domain, providers, mainDomain: isPlatformBrowser(this.platformId) ? formatMainDomain() : this.req.baseUrl }),
            ];
          }),
          catchError((error: HttpErrorResponse) => {
            return of(DomainActions.loadDomainFailure({ error: error.message }));
          })
        );
      })
    ));

  updateDomain$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DomainActions.updateDomain),
      withLatestFrom(
        this.store$.select(fromRoot.getActiveTeam),
      ),
      concatMap(([action, teamToUpdate]) => {
        const teamName = action.team.name;
        const newDomainUrl = action.team.domain;
        const domainSdk = new DomainSdk();
        return from(domainSdk.updateDomain({ domainId: teamToUpdate.domain, domain: newDomainUrl })).pipe(
          concatMap(() => {
            return this.iamService.updateTeam(teamToUpdate.id, { name: teamName }).pipe(
              concatMap(() => {
                return of(DomainActions.updateDomainSuccess());
              }));
          }),
          catchError((err: HttpErrorResponse) => {
            const error = err.status;
            return of(DomainActions.updateDomainFailure({ error }));
          })
        );
      }),
    ));

  loadDomainCustomization$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DomainActions.loadDomainCustomization),
      withLatestFrom(
        this.store$.select(fromRoot.getActiveTeam),
      ),
      switchMap(([action, team]: any) => {
        if (!team) {
          return [{ type: 'No team selected' }];
        }
        // when team is null, cause an error when user signout
        const domainSdk = new DomainSdk();
        return from(domainSdk.getDomainCustomization({ domainId: team.domain })).pipe(
          map((res) => {
            const customizationFallback = Constant.customizationFallback;
            return DomainActions.loadDomainCustomizationSuccess({ customization: { ...customizationFallback, ...res.customization } });
          }),
          retryWhen(error =>
            error.pipe(
              take(3),
              delay(2000)
            )
          ),
          catchError((error: HttpErrorResponse) => {
            return of(DomainActions.loadDomainCustomizationFailure({ error: error.message }));
          }
          )
        );
      })
    )
  );

  addNewBackgroundImageToDomainCustomization = createEffect(() =>
    this.actions$.pipe(
      ofType(DomainActions.addNewBackgroundImageToDomainCustomization),
      map(action => action.image),
      concatMap(([image]: any[]) => {
        const imageSdk = new Image();
        return forkJoin([of(image), from(imageSdk.createImage())])
      }),
      concatMap(([image, res]: any[]) => forkJoin([of(res), this.imageService.uploadImage(res.image.uploadUrl, image)])),
      concatMap(([res, resUpload]: any[]) => {
        return of(DomainActions.addNewBackgroundImageToDomainCustomizationSuccess({ background: res.image }));
      })
    )
  );

  addNewBackgroundVideoToDomainCustomization = createEffect(() =>
    this.actions$.pipe(
      ofType(DomainActions.addNewBackgroundVideoToDomainCustomization),
      map(action => action.url),
      concatMap((url: any) => {
        const video = getVideoIdFromUrl(url);
        return of(DomainActions.addNewBackgroundVideoToDomainCustomizationSuccess({ video }));
      })
    )
  );

  addNewLogoToDomainCustomization = createEffect(() =>
    this.actions$.pipe(
      ofType(DomainActions.addNewLogoToDomainCustomization),
      map(action => action.image),
      concatMap(([logo]: any[]) => {
        const imageSdk = new Image();
        return forkJoin([of(logo), from(imageSdk.createImage())])
      }),
      concatMap(([logo, { image }]) => {
        return forkJoin([of([logo, { image }]), this.imageService.uploadImage(image.uploadUrl, logo)])
      }),
      concatMap(([[logo, { image }]]) => {
        return [
          DomainActions.addNewLogoToDomainCustomizationSuccess({ logo: image }),
        ];
      })
    )
  );

  updateDomainCustomization$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DomainActions.updateDomainCustomization),
      withLatestFrom(
        this.store$.select(fromCustomization.getCustomizationToUpdate),
        this.store$.select(fromRoot.getActiveTeam),
      ),
      switchMap(([action, customizationToUpdate, team]: any) => {
        let logo; let background;
        if (customizationToUpdate.logo && customizationToUpdate.logo.sourceUrl) {
          logo = customizationToUpdate.logo.sourceUrl;
        }
        if (customizationToUpdate.background && customizationToUpdate.background.sourceUrl) {
          background = customizationToUpdate.background.sourceUrl;
        }
        const domainSdk = new DomainSdk();
        return from(domainSdk.updateDomainCustomization({ domainId: team.domain, logo, background })).pipe(
          mergeMap(() => {
            return [
              DomainActions.loadDomain({ id: team.domain }),
              UserCustomizationActions.updateCustomizationSuccess()
            ];
          }),
          catchError((error: any) => {
            return of(DomainActions.updateDomainCustomizationFailure({ error: error.message }));
          }
          )
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private store$: Store<fromCustomization.State & fromRoot.State>,
    private imageService: ImageService,
    private iamService: IamService,
    @Optional() @Inject(REQUEST) private req: Request,
    @Inject(PLATFORM_ID) private platformId: any
  ) { }

}
