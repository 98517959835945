import { FactorStatusType, FactorType, FactorVerificationStatusType } from '@app/shared/models/mfa';
import { createAction, props } from '@ngrx/store';
import { Region } from '@smash-sdk/core';

// Display Mfa Challenge
export const DisplayMfaChallenge = createAction(
  '[Mfa] Display Mfa Challenge',
  props<{ token: string, region: Region, mfa: { defaultFactor: FactorType, factors: { [key in FactorType]: { status: FactorStatusType, verificationStatus: FactorVerificationStatusType, secret?: string } } } }>()
);

// Reset Mfa
export const ResetMfa = createAction(
  '[Mfa] Reset Mfa'
);

// Send Otp
export const SendOtp = createAction(
  '[Mfa] Send Otp',
  props<{ factor: FactorType }>()
);
export const SendOtpSuccess = createAction(
  '[Mfa] Send Otp Success',
);
export const SendOtpFailure = createAction(
  '[Mfa] Send Otp Failure',
  props<{ error: any }>()
);

// Verify Otp
export const VerifyOtp = createAction(
  '[SigninMobile] Verify Otp',
  props<{ factor: FactorType, otp: string }>()
);
export const VerifyOtpSuccess = createAction(
  '[SigninMobile] Verify Otp Success',
  props<{ identity: any, region?: string }>()
);
export const VerifyOtpFailure = createAction(
  '[SigninMobile] Verify Otp Failure',
  props<{ error: any }>()
);

// Verify Email Factor
export const VerifyEmailFactor = createAction(
  '[Mfa] Verify Email Factor',
  props<{ otp: string }>()
);

export const VerifyEmailFactorSuccess = createAction(
  '[Mfa] Verify Email Factor Success',
);

export const VerifyEmailFactorFailure = createAction(
  '[Mfa] Verify Email Factor Failure',
  props<{ error: any }>()
);

// Verify Application Factor
export const VerifyApplicationFactor = createAction(
  '[Mfa] Verify Application Factor',
  props<{ otp: string }>()
);

export const VerifyApplicationFactorSuccess = createAction(
  '[Mfa] Verify Application Factor Success',
);

export const VerifyApplicationFactorFailure = createAction(
  '[Mfa] Verify Application Factor Failure',
  props<{ error: any }>()
);

// Create Mfa Factor Secret
export const CreateMfaFactorSecret = createAction(
  '[Mfa] Create Mfa Factor Secret',
  props<{ factor: FactorType }>()
);

export const CreateMfaFactorSecretSuccess = createAction(
  '[Mfa] Create Mfa Factor Secret Success',
  props<{ secret: string }>()
);

export const CreateMfaFactorSecretFailure = createAction(
  '[Mfa] Create Mfa Factor Secret Failure',
  props<{ error: any }>()
);
